import React from 'react';
import { connect } from 'react-redux';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import selectors from '../../../../store/selectors';

const mapStateToProps = state => ({ topLanguages: selectors.getTopLanguages(state) });

const enhance = connect(mapStateToProps);

const TopLanguagesChart = ({ topLanguages }) => {
  const displayedTopLanguages = topLanguages.slice(0, 5);

  return (
    <div style={{ height: 240 }}>
      <ResponsiveContainer>
        <BarChart data={displayedTopLanguages}>
          <XAxis
            dataKey="language"
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            dataKey="value"
            axisLine={false}
            tickLine={false}
          />
          <Tooltip />
          <Bar
            barSize={20}
            dataKey="value"
            fill="#009fff"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default enhance(TopLanguagesChart);

import { all, takeLatest, put } from 'redux-saga/effects';
import qs from 'query-string'

import { types, actions } from '../../store/actions';
import { Dialog } from '../../common/containers';

function* requestInfluencerData({ influencerSearchParams }) {
  const base = 'https://guarded-hollows-12603.herokuapp.com/search';

  const params = qs.stringify(
    {
      ...influencerSearchParams,
      campaign_goal: influencerSearchParams.campaign_goal || 'awareness',
      limit: '100',
      key: 't2Ka36F4M6Mq5FvgfG7CQVQkWKYHPasMZ5TqdfLwXcCAMSqzNRT4bhTmjnbSkqUDXJAPDgLe',
    },
    {
      arrayFormat: 'comma',
    }
  );

  try {
    const url = `${base}?${params}`;

    const response = yield fetch(url);

    const parsed = yield response.json()

    yield put(actions.influencerDataReceived({ result: parsed }));
  } catch (ex) {
    Dialog.show({
      caption: 'Failed to influencer data',
      message: ex.message,
      buttons: ['Got it'],
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(types.REQUEST_INFLUENCER_DATA, requestInfluencerData),
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';

export const InputField = ({
  input, label, type, placeholder, meta: { touched, error },
}) => {
  return (
    <div className="form-group">
      <label className="text-normal text-dark">{label}</label>
      {touched && error && <span className="error">&nbsp;{error}</span>}
      <div>
        <input className="form-control" {...input} placeholder={placeholder} type={type}/>
      </div>
    </div>
  );
};

InputField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

import {
  all, takeEvery, call, put,
} from 'redux-saga/effects';

import { actions, types } from '../../store/actions';

function* searchInfluencer({ game_title_or_sector }) {
  console.log('sagas', game_title_or_sector);
  //call(fetch, 'https://guarded-hollows-12603.herokuapp.com/search?campaign_goal=awareness&limit=10&influencer_language=English&campaign_budget=2991869');
  let response = yield (async () => {
    return await fetch('https://guarded-hollows-12603.herokuapp.com/search?campaign_goal=awareness&limit=10&influencer_language=English&campaign_budget=2991869');
  })();
  //put( );
}

export default function* root() {
  yield all([
    takeEvery(types.SEARCH_INFLUENCER, searchInfluencer),
  ]);
}

function clamp(min, max) {
  return function (number) {
    return Math.min(
      Math.max(
        number,
        min,
      ),
      max
    )
  }
}

export default clamp

import { withHandlers, compose } from 'recompose';

import { UserIsNotAuthenticated } from '../../../../common/auth';
import { Dialog } from '../../../../common/containers';
import { actions } from '../../../../store/actions';

export default compose(
  UserIsNotAuthenticated,
  withHandlers({
    onSubmitFail: () => () => {
      Dialog.show({
        caption: 'Failed',
        message: 'Please fill all required fields',
        buttons: ['Got it'],
      });
    },
    emailSubmit: ({ dispatch }) => async ({ email }) => {
      dispatch(actions.resetPassword(email));
    },
  })
);

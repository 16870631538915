import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';

const formik = {
  handleSubmit: ({ attitude, characteristic }, bag) => {
    bag.props.onSubmit(`${attitude} ${characteristic}`);
    bag.props.close();
  },
  mapPropsToValues: ({ characteristics: [characteristic] }) => ({
    characteristic,
    attitude: 'More likely',
  }),
};

const enhance = withFormik(formik);

const AudienceCharacteristicForm = ({
  values,
  characteristics,
  handleChange,
  handleSubmit,
}) => {
  return (
    <div className="card" style={{ minWidth: 400 }}>
      <div className="card-body">
        <div className="form-group">
          <label>
            Attitude
            <select
              name="attitude"
              value={values.attitude}
              onChange={handleChange}
              className="form-control"
            >
              <option>More likely</option>
              <option>Less likely</option>
            </select>
          </label>
        </div>
        <div className="form-group">
          <label>
            Preference
            <select
              name="characteristic"
              value={values.characteristic}
              onChange={handleChange}
              className="form-control"
            >
              {characteristics.map(trait => <option key={trait}>{trait}</option>)}
            </select>
          </label>
        </div>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={handleSubmit}
        >
          ADD
      </button>
      </div>
    </div>
  );
};

const EnhancedAudienceCharacteristicForm = enhance(AudienceCharacteristicForm);

EnhancedAudienceCharacteristicForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EnhancedAudienceCharacteristicForm;

import { STORAGE_PREFIX as prefix } from './redux';

export const getAuthInProgress = state => state[prefix].authInProgress;
export const getUser = state => state[prefix].authUser;
export const getUserName = state => (
  state[prefix].authUser
    ? state[prefix].authUser.displayName || ''
    : ''
);
export const getUserEmail = state => (
  state[prefix].authUser
    ? state[prefix].authUser.email || ''
    : ''
);
export const getUserImage = state => (
  state[prefix].authUser
    ? state[prefix].authUser.photoURL || ''
    : ''
);

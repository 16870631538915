import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik } from 'formik';
import { compose } from 'recompose';
import qs from 'query-string';

import { selectors } from '../../../../store/selectors';
import { UserIsAuthenticated } from '../../../../common/auth';
import * as paths from '../../../../common/constants/paths';
import objectFilter from '../../../../utils/object-filter'

const handleSubmit = (values, bag) => {
  const { props: { history } } = bag;

  const completedParams = objectFilter(value => Boolean(value))(values)

  const query = qs.stringify(completedParams, { arrayFormat: 'comma' });

  history.push({
    pathname: paths.INFLUENCERS,
    search: query,
  });
};

const mapPropsToValues = () => ({
  influencer_network: 'twitch',
  influencer_size: '',
  influencer_game_title_or_sector: '',
  influencer_language: '',
  influencer_location: '',
  influencer_keywords: [],

  audience_age_groups: [],
  audience_gender: '',
  audience_interest_categories: [],
  audience_interest_keywords: [],
  audience_locations: [],
  audience_languages: [],
  audience_purchasing_preferences: [],
  audience_lifestyle_preferences: [],
  audience_purchasing_behaviours: [],
  audience_lifestyle_behaviours: [],

  campaign_goal: '',
  campaign_budget: '',
});

const formik = {
  handleSubmit,
  mapPropsToValues,
};

const mapStateToProps = state => ({
  game_title_or_sector: selectors.getGameTitleOrSector(state),
});

export default compose(
  UserIsAuthenticated,
  connect(mapStateToProps),
  withRouter,
  withFormik(formik)
);

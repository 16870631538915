import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { createBrowserHistory } from 'history';
import { PATH_SIGNIN, PATH_ROOT } from '../common/constants/paths';
import Firebase from '../common/resources/firebase';

const locationHelper = locationHelperBuilder({});
export const history = createBrowserHistory();

export const UserIsAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsAuthenticated',
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => (
    locationHelper.getRedirectQueryParam(ownProps) || PATH_SIGNIN
  ),
  authenticatingSelector: () => !Firebase.isLoaded,
  authenticatedSelector: ({ account: { authUser } }) => Firebase.isLoaded && !!authUser,
  redirectAction: newLoc => () => history.replace(newLoc),
});

export const UserIsNotAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || PATH_ROOT,
  authenticatingSelector: () => !Firebase.isLoaded,
  authenticatedSelector: ({ account: { authUser } }) => Firebase.isLoaded && !authUser,
  redirectAction: newLoc => () => history.replace(newLoc),
});

import { createReducer, createActions } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import persistStorage from 'redux-persist/lib/storage';

export const STORAGE_PREFIX = 'dialog';

export const INITIAL_STATE = {
  caption: '',
  message: '',
  buttons: [],
};

const { Types, Creators } = createActions({
  dialogShow: ['caption', 'message', 'buttons'],
  dialogAccept: ['payload'],
  dialogCancel: null,
}, {
  prefix: `${STORAGE_PREFIX  }/`,
});

/* reducers */

export const dialogShow = (state, { caption, message, buttons }) => {
  return { caption, message, buttons };
};

export const dialogAccept = state => INITIAL_STATE;
export const dialogCancel = state => INITIAL_STATE;

export const HANDLERS = {
  [Types.DIALOG_SHOW]: dialogShow,
  [Types.DIALOG_ACCEPT]: dialogAccept,
  [Types.DIALOG_CANCEL]: dialogCancel,
};

const persistConfig = {
  key: STORAGE_PREFIX,
  storage: persistStorage,
  whitelist: [],
};

export const reducer = persistReducer(persistConfig, createReducer(INITIAL_STATE, HANDLERS));
export const actionTypes = Types;
export default Creators;

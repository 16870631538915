import React from 'react'
import { withRouter } from 'react-router-dom'

function GoBack ({ children, history }) {
  return React.cloneElement(
    children,
    { onClick: history.goBack }
  )
}

export default withRouter(GoBack)

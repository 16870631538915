import React from 'react';
import PropTypes from 'prop-types';
import anonAvatar from '../../../assets/images/anon.jpg';

export const TopBar = ({
  userName,
  userImage,
  onSignout,
}) => {
  return (
    <div className="header navbar">
    <div className="header-container">
      <ul className="nav-left">
        <li>
          <a id="sidebar-toggle" className="sidebar-toggle">
            <i className="ti-menu" />
          </a>
        </li>
      </ul>
      <ul className="nav-right">
        <li className="dropdown">
          <a className="dropdown-toggle no-after peers fxw-nw ai-c lh-1" data-toggle="dropdown">
            <div className="peer mR-10">
              <img className="w-2r bdrs-50p" src={userImage || anonAvatar} />
            </div>
            <div className="peer">
              <span className="fsz-sm c-grey-900">{userName}</span>
            </div>
          </a>
          <ul 
              className="dropdown-menu fsz-sm"
              onClick={onSignout}>
            <li>
              <a className="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                <i className="ti-power-off mR-10" />
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  /*
    <div className="header navbar">
      <div className="header-container">
        <ul className="nav-left">
        </ul>
        <ul className="nav-right" id="topNav">
          <li
            className="dropdown"
          >
            <a className="dropdown-toggle no-after peers fxw-nw ai-c lh-1" data-toggle="dropdown" aria-expanded="true">
              <div className="peer mR-10">
                <img alt="" className="w-2r bdrs-50p" src={userImage || anonAvatar} />
              </div>
              <div className="peer">
                <span className="fsz-sm c-grey-900">{userName}</span>
              </div>
            </a>
            <ul
              className="dropdown-menu fsz-sm"
              onClick={onSignout}
            >
              <li>
                <a className="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                  <i className="ti-power-off mR-10" />
                  <span>Logout</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    */
  );
};

TopBar.propTypes = {
  userName: PropTypes.string.isRequired,
  userImage: PropTypes.string.isRequired,
  onSignout: PropTypes.func.isRequired,
};

import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import makeRootReducer from './reducers';
import rootSaga from './sagas';

export const store = (() => {
  // ======================================================
  // Middleware Configuration
  // ======================================================

  const sagaMiddleware = createSagaMiddleware();
  const middleware = [
    sagaMiddleware,
  ];

  // ======================================================
  // Store Initialization
  // ======================================================

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const storeInstance = createStore(
    makeRootReducer(),
    composeEnhancers(
      applyMiddleware(...middleware)
    )
  );
  /*
  storeInstance.asyncReducers = {};
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default;
      storeInstance.replaceReducer(reducers(storeInstance.asyncReducers));
    });
  }
*/
  sagaMiddleware.run(rootSaga);
  return storeInstance;
})();

export const persistor = persistStore(store);

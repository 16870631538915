import React from 'react';
import { FieldArray, Field } from 'formik';
import { Persist } from 'formik-persist';
import classNames from 'classnames';

import MenuDrawer from '../../../../common/components/MenuDrawer';
import Popover from '../../../../common/components/Popover';
import TopBar from '../../../../common/components/TopBar';
import Chip from '../../../../common/components/Chip';

import AudienceKeywordForm from '../AudienceKeywordForm';
import AudienceCharacteristicForm from '../AudienceCharacteristicForm';
import SelectTermPopoverMenu from '../SelectTermPopoverMenu';

export default class InfluencerSearchPage extends React.Component {
  state = {
    audienceInterestKeywordFormIsOpen: false,
    audiencePurchasingPreferenceFormIsOpen: false,
    audienceLifestylePreferenceFormIsOpen: false,
    audiencePurchasingBehaviourFormIsOpen: false,
    audienceLifestyleBehaviourFormIsOpen: false,
  }

  componentDidUpdate (prevProps) {
    const { setFieldValue } = this.props

    if (this.networkHasChanged(prevProps)) {
      /*
       * whether or not an influencer is search-able by location
       * or language depends on the selected network e.g. for
       * youtube there is no 'language' data available, only location
       *
       * because we don't want to send un-necessary information together
       * with a query we reset both these parameters should the user
       * decide to change the selected network
       */
      setFieldValue('influencer_language', '');
      setFieldValue('influencer_location', '');
    }
  }

  networkHasChanged (prevProps) {
    return prevProps.influencer_network !== this.props.influencer_network
  }

  /*
   * This openCertainForm and closeCertainForm is not very dry but meh,
   * if anybody can find a better way not to repeat this code other than
   * doing something like:
   *
   * <Button onClick={() => openSpecificForm('form-name')} />
   *
   * which is esentially the same, only that the functions are anonymous
   * and defined inline, which is ugly & bloating, please do.
   *
   * Putting the toggled-state inside of the forms themselves is an
   * anti-pattern in regards to the controlled-components mentality
   * and it can cause problem should you have to support features where
   * you need to know the toggled state of a form outside of it e.g.
   * for features such as 'are you sure you want to abandon this?', etc.
   *
   * Besides, already did that for the menus in order to avoid this heavy
   * duplication and that felt dirty enough, so, let's leave it at that :)
   */
  openAudienceInterestKeywordForm = () => {
    this.setState({ audienceInterestKeywordFormIsOpen: true });
  }

  closeAudienceInterestKeywordForm = () => {
    this.setState({ audienceInterestKeywordFormIsOpen: false });
  }

  openAudiencePurchasingPreferenceForm = () => {
    this.setState({ audiencePurchasingPreferenceFormIsOpen: true });
  }

  closeAudiencePurchasingPreferenceForm = () => {
    this.setState({ audiencePurchasingPreferenceFormIsOpen: false });
  }

  openAudienceLifestylePreferenceForm = () => {
    this.setState({ audienceLifestylePreferenceFormIsOpen: true });
  }

  closeAudienceLifestylePreferenceForm = () => {
    this.setState({ audienceLifestylePreferenceFormIsOpen: false });
  }

  openAudiencePurchasingBehaviourForm = () => {
    this.setState({ audiencePurchasingBehaviourFormIsOpen: true });
  }

  closeAudiencePurchasingBehaviourForm = () => {
    this.setState({ audiencePurchasingBehaviourFormIsOpen: false });
  }

  openAudienceLifestyleBehaviourForm = () => {
    this.setState({ audienceLifestyleBehaviourFormIsOpen: true });
  }

  closeAudienceLifestyleBehaviourForm = () => {
    this.setState({ audienceLifestyleBehaviourFormIsOpen: false });
  }

  clearSearch = () => {
    this.props.resetForm();
  }

  handleNetworkSelection = network => {
    const { setFieldValue } = this.props;

    setFieldValue('influencer_network', network);
  }

  handleSubmit = event => {
    /*
     * prevent default form submission by hitting 'enter' while inside
     * the form in order not to trigger submission when the user hits
     * enter on a sub-form (e.g. add-keyword-form)
     *
     * TODO: the problem of idiomatically nesting forms is a little trickier
     * and requires separate attention, generally speaking it's considered an
     * anti-pattern but let's see what we can do about that
     */
    event.preventDefault();
  }

  renderSearch = () => {
    return (
      <div className="filters-bar">
        <ul className="nav-left">
          <li>
            <h1 className="top-title">Search for influencers</h1>
          </li>
        </ul>
        <ul className="nav-right">
          <li>
            <button
              type="button"
              className="btn cur-p btn-outline-primary"
              onClick={this.clearSearch}
            >
              <i className="ia fa-times" style={{ fontSize: 18 }} />
              CLEAR SEARCH
            </button>
            <button
              className="btn cur-p btn-primary"
              onClick={this.props.submitForm}
            >
              <i className="ia fa-search" />
              SEARCH INFLUENCERS
            </button>
          </li>
        </ul>
      </div>
    );
  }

  renderInfluencerNetwork = () => {
    const { values } = this.props;

    const twitchSelectionClassName = classNames(
      'option-selector',
      {
        'option-selected': values.influencer_network === 'twitch',
      }
    );

    const youtubeSelectionClassName = classNames(
      'option-selector',
      {
        'option-selected': values.influencer_network === 'youtube',
      }
    );

    return (
      <div className="option-content">
        <p className="option-title">Network</p>
        <ul className="option-list">
          <li>
            <button
              type="button"
              onClick={() => this.handleNetworkSelection('twitch')}
              className={twitchSelectionClassName}
            >
              Twitch
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => this.handleNetworkSelection('youtube')}
              className={youtubeSelectionClassName}
            >
              Youtube
            </button>
          </li>
        </ul>
      </div>
    );
  }

  renderInfluencerSize = () => {
    const { handleChange, values } = this.props;

    return (
      <div className = "option-content">
        <label className = "option-title">Influencer size</label>
        <ul className="option-list">
          <li>
            <select
              name="influencer_size"
              value={values.influencer_size}
              onChange={handleChange}
              className="form-control d-inline w-auto"
            >
              <option value="">Any</option>
              <option value="NANO INFLUENCER">Nano influencer</option>
              <option value="MICRO INFLUENCER">Micro influencer</option>
              <option value="MACRO INFLUENCER">Macro influencer</option>
              <option value="MEGA INFLUENCER">Mega influencer</option>
            </select>
          </li>
        </ul>
      </div>
    );
  }

  renderInfluencerGameTitleOrSector = () => {
    return (
      <div className="option-content">
        <label className="option-title">Game title or sector</label>
        <ul className="option-list">
          <li>
            <Field
              component="input"
              type="text"
              name="influencer_game_title_or_sector"
              className="form-control w-280"
              placeholder="Game title or game sector"
            />
          </li>
        </ul>
      </div>
    );
  }

  renderInfluencerLanguage = () => {
    const { values, handleChange } = this.props;

    return (
      <div className="option-content">
        <label className="option-title">Language</label>
        <ul className="option-list">
          <li>
            <select
              name="influencer_language"
              value={values.influencer_language}
              onChange={handleChange}
              className="form-control d-inline w-auto"
            >
              <option value="">Any</option>
              <option value="English">English</option>
              <option value="Spanish">Spanish</option>
              <option value="Chinese">Chinese</option>
              <option value="French">French</option>
              <option value="Korean">Korean</option>
              <option value="Portuguese">Portuguese</option>
              <option value="Swedish">Swedish</option>
              <option value="Arabic">Arabic</option>
              <option value="Japanese">Japanese</option>
              <option value="Turkish">Turkish</option>
              <option value="Italian">Italian</option>
              <option value="Hungarian">Hungarian</option>
              <option value="Russian">Russian</option>
              <option value="Finnish">Finnish</option>
              <option value="German">German</option>
              <option value="Dutch">Dutch</option>
              <option value="Polish">Polish</option>
              <option value="Thai">Thai</option>
              <option value="Czech">Czech</option>

            </select>
          </li>
        </ul>
      </div>
    );
  }

  renderInfluencerLocation = () => {
    const { values, handleChange } = this.props;

    return (
      <div className="option-content">
        <label className="option-title">Location</label>
        <ul className="option-list">
          <li>
            <select
              name="influencer_location"
              value={values.influencer_location}
              onChange={handleChange}
              className="form-control d-inline w-auto"
            >
              <option value="">Any</option>
            </select>
          </li>
        </ul>
      </div>
    );
  }

  renderInfluencer = () => {
    const { values } = this.props

    return (
      <>
        <div className="masonry-item  w-100">
          <h2>Influencer</h2>
        </div>
        <div className="masonry-item w-100">
          <div className="row">
            <div className="col-md-12">
              <div className="bd bgc-white p-20 clearfix">
                <div className="filtering-options">

                  {this.renderInfluencerNetwork()}

                  {this.renderInfluencerSize()}

                  {this.renderInfluencerGameTitleOrSector()}

                  {/*
                  
                  TODO: switch between language & location based on
                  twitch (language) vs youtube (location) once the
                  back-end is ready to handle that difference

                  */}
                  {this.renderInfluencerLanguage()}

                  {this.renderInfluencerKeyword()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  ageGroups = [
    '18-25',
    '26-25',
    '35-44',
    '45-54',
    '55-64',
    '65+',
  ]

  sortAgeGroupsByAge = ageGroups => {
    return ageGroups.sort(
      (ageGroupOne, ageGroupTwo) => (
        this.ageGroups.indexOf(ageGroupOne) - this.ageGroups.indexOf(ageGroupTwo)
      )
    );
  }

  renderAudienceAgeGroups = () => {
    const { values } = this.props;

    const availableAgeGroups = this.sortAgeGroupsByAge(
      this.ageGroups
        .filter(ageGroup => !values.audience_age_groups.includes(ageGroup))
    );

    const selectedAgeGroups = this.sortAgeGroupsByAge(values.audience_age_groups);

    return (
      <FieldArray
        name="audience_age_groups"
        render={
          ({ remove, push }) => (
            <div className="option-content">
              <p className="option-title">Age group</p>
              <ul className="option-list removable-option">
                {
                  selectedAgeGroups.map(
                    (ageGroup, index) => (
                      <li key={ageGroup}>
                        <Chip label={ageGroup} onDelete={() => remove(index)} />
                      </li>
                    )
                  )
                }

                {
                  availableAgeGroups.length > 0 && (
                    <li>
                      <SelectTermPopoverMenu
                        terms={availableAgeGroups}
                        onSelect={push}
                      />
                    </li>
                  )
                }
              </ul>
            </div>
          )
        }
      />
    );
  }

  renderAudienceGender = () => {
    const { handleChange } = this.props;

    return (
      <div className="option-content">
        <label className="option-title">Gender</label>
        <ul className="option-list">
          <li>
            <select
              name="audience_gender"
              onChange={handleChange}
              className="form-control d-inline w-auto"
            >
              <option>Any</option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </li>
        </ul>
      </div>
    );
  }

  locations = [
    'China',
    'France',
    'Germany',
    'United Kingdom',
    'United States',
  ]

  renderAudienceLocations = () => {
    const { values } = this.props;

    const availableLocations = this.locations.filter(
      location => !values.audience_locations.includes(location)
    );

    return (
      <FieldArray
        name="audience_locations"
        render={
          ({ remove, push }) => (
            <div className="option-content">
              <p className="option-title">Location</p>
              <ul className="option-list removable-option">
                {
                  values.audience_locations.map(
                    (language, index) => (
                      <li key={language}>
                        <Chip label={language} onDelete={() => remove(index)} />
                      </li>
                    )
                  )
                }

                {
                  availableLocations.length > 0 && (
                    <li>
                      <SelectTermPopoverMenu
                        terms={availableLocations}
                        onSelect={push}
                      />
                    </li>
                  )
                }
              </ul>
            </div>
          )
        }
      />
    );
  }

  languages = [
    'English',
    'Chinese',
    'French',
    'German',
  ]

  renderAudienceLanguages = () => {
    const { values } = this.props;

    const availableLanguages = this.languages.filter(
      language => !values.audience_languages.includes(language)
    );

    return (
      <div className="option-content">
        <label className="option-title">Language</label>
        <FieldArray
          name="audience_languages"
          render={
            ({ push, remove }) => (
              <ul className="option-list removable-option">

                {
                  values.audience_languages.map(
                    (language, index) => (
                      <li key={language}>
                        <Chip label={language} onDelete={() => { remove(index); }} />
                      </li>
                    )
                  )
                }

                {
                  availableLanguages.length > 0 && (
                    <li className="dropdown-option">
                      <SelectTermPopoverMenu
                        terms={availableLanguages}
                        onSelect={push}
                      />
                    </li>
                  )
                }
              </ul>
            )
          }
        />
      </div>
    );
  }

  categories = [
    'Movies and tv',
    'Fashion industry',
    'Beverages',
    'Food',
    'Technology',
  ]

  renderAudienceCategoryInterests = () => {
    const { values } = this.props;

    const availableCategories = this.categories.filter(
      category => !values.audience_interest_categories.includes(category)
    );

    return (
      <div className="option-content">
        <p className="option-title">Category</p>
        <FieldArray
          name="audience_interest_categories"
          render={
            ({ push, remove }) => (
              <ul className="option-list removable-option">

                {
                  values.audience_interest_categories.map(
                    (category, index) => (
                      <li key={category}>
                        <Chip label={category} onDelete={() => { remove(index); }} />
                      </li>
                    )
                  )
                }

                {
                  availableCategories.length > 0 && (
                    <li className="dropdown-option">
                      <SelectTermPopoverMenu
                        terms={availableCategories}
                        onSelect={push}
                      />
                    </li>
                  )
                }
              </ul>
            )
          }
        />
      </div>
    );
  }

  renderInfluencerKeyword = () => {
    const { values } = this.props;

    const { audienceInterestKeywordFormIsOpen } = this.state;

    return (
      <div className="option-content">
        <label className="option-title">Content</label>
        <FieldArray
          name="influencer_keywords"
          render={
            ({ push, remove }) => (
              <ul className="option-list removable-option">
                {
                  values.influencer_keywords.map(
                    (keyword, index) => (
                      <li key={keyword}>
                        <Chip label={keyword} onDelete={() => remove(index)} />
                      </li>
                    )
                  )
                }

                <li className="dropdown-option">
                  <Popover
                    under
                    align="left"
                    open={audienceInterestKeywordFormIsOpen}
                    onClose={this.closeAudienceInterestKeywordForm}
                    content={(
                      <AudienceKeywordForm
                        close={this.closeAudienceInterestKeywordForm}
                        onSubmit={({ keyword }) => push(keyword)}
                      />
                    )}
                  >
                    <button
                      onClick={this.openAudienceInterestKeywordForm}
                      className="btn btn-outline-primary option-add"
                      aria-haspopup="true"
                      aria-expanded={audienceInterestKeywordFormIsOpen}
                    >
                      <i className="ia fa-plus" />
                    </button>
                  </Popover>
                </li>

                {
                  values.influencer_keywords.length === 0 && (
                    <li>
                      <p className="empty-filter-message">
                        No keyword added
                      </p>
                    </li>
                  )
                }
              </ul>
            )
          }
        />
      </div>
    );
  }

  renderAudienceKeywordInterests = () => {
    const { values } = this.props;

    const { audienceInterestKeywordFormIsOpen } = this.state;

    return (
      <div className="option-content">
        <label className="option-title">Talking about</label>
        <FieldArray
          name="audience_interest_keywords"
          render={
            ({ push, remove }) => (
              <ul className="option-list removable-option">
                {
                  values.audience_interest_keywords.map(
                    (keyword, index) => (
                      <li key={keyword}>
                        <Chip label={keyword} onDelete={() => remove(index)} />
                      </li>
                    )
                  )
                }

                <li className="dropdown-option">
                  <Popover
                    under
                    align="left"
                    open={audienceInterestKeywordFormIsOpen}
                    onClose={this.closeAudienceInterestKeywordForm}
                    content={(
                      <AudienceKeywordForm
                        close={this.closeAudienceInterestKeywordForm}
                        onSubmit={({ keyword }) => push(keyword)}
                      />
                    )}
                  >
                    <button
                      onClick={this.openAudienceInterestKeywordForm}
                      className="btn btn-outline-primary option-add"
                      aria-haspopup="true"
                      aria-expanded={audienceInterestKeywordFormIsOpen}
                    >
                      <i className="ia fa-plus" />
                    </button>
                  </Popover>
                </li>

                {
                  values.audience_interest_keywords.length === 0 && (
                    <li>
                      <p className="empty-filter-message">
                        No keyword added
                      </p>
                    </li>
                  )
                }
              </ul>
            )
          }
        />
      </div>
    );
  }

  renderAudienceCharacteristics = ({
    title,
    name,
    preferences,
    characteristics,
    formIsOpen,
    openForm,
    closeForm,
  }) => {
    return (
      <div className="option-content">
        <p className="option-title">
          {title}
        </p>
        <FieldArray
          name={name}
          render={
            ({ push, remove }) => (
              <ul className="option-list removable-option">
                {
                  preferences.map(
                    (preference, index) => (
                      <li key={preference}>
                        <Chip label={preference} onDelete={() => remove(index)} />
                      </li>
                    )
                  )
                }

                <li className="dropdown-option">
                  <Popover
                    over
                    align="left"
                    open={formIsOpen}
                    onClose={closeForm}
                    content={(
                      <AudienceCharacteristicForm
                        close={closeForm}
                        onSubmit={push}
                        characteristics={characteristics}
                      />
                    )}
                  >
                    <button
                      onClick={openForm}
                      className="btn btn-outline-primary option-add"
                      aria-haspopup="true"
                      aria-expanded={formIsOpen}
                    >
                      <i className="ia fa-plus" />
                    </button>
                  </Popover>
                </li>
              </ul>
            )
          }
        />
      </div>
    );
  }

  renderAudiencePurchasingPreferences = () => {
    const { audiencePurchasingPreferenceFormIsOpen } = this.state;

    const { values: { audience_purchasing_preferences } } = this.props;

    return this.renderAudienceCharacteristics({
      title: 'Purchasing preferences',
      name: 'audience_purchasing_preferences',
      preferences: audience_purchasing_preferences,
      formIsOpen: audiencePurchasingPreferenceFormIsOpen,
      openForm: this.openAudiencePurchasingPreferenceForm,
      closeForm: this.closeAudiencePurchasingPreferenceForm,
      characteristics: [
        'to prefer quality when buying clothes',
        'to prefer comfort when buying clothes',
        'to prefer style when buying clothes',
        'to prefer safety when buying automobiles',
      ],
    });
  }

  renderAudienceLifestylePreferences = () => {
    const { audienceLifestylePreferenceFormIsOpen } = this.state;

    const { values: { audience_lifestyle_preferences } } = this.props;

    return this.renderAudienceCharacteristics({
      title: 'Lifestyle preferences',
      name: 'audience_lifestyle_preferences',
      preferences: audience_lifestyle_preferences,
      formIsOpen: audienceLifestylePreferenceFormIsOpen,
      openForm: this.openAudienceLifestylePreferenceForm,
      closeForm: this.closeAudienceLifestylePreferenceForm,
      characteristics: [
        'to like romance movies',
        'to like science-fiction movies',
        'to like war movies',
        'to like adventure movies',
        'to like historical movies',
        'to like action movies',
        'to like documentary movies',
        'to like outdoor activities',
        'to like horror movies',
        'to like musical movies',
        'to like drama movies',
        'to like rock music',
        'to like classical music',
        'to like rap music',
        'to like R&B music',
        'to like hip hop music',
        'to like Latin music',
        'to like country music',
        'to read non-fiction books',
        'to read autobiographical books',
        'to read entertainment magazines',
        'to read financial investment books',
      ],
    });
  }

  renderAudiencePurchasingBehaviour = () => {
    const { audiencePurchasingBehaviourFormIsOpen } = this.state;

    const { values: { audience_purchasing_behaviours } } = this.props;

    return this.renderAudienceCharacteristics({
      title: 'Purchasing behaviours',
      name: 'audience_purchasing_behaviours',
      preferences: audience_purchasing_behaviours,
      formIsOpen: audiencePurchasingBehaviourFormIsOpen,
      openForm: this.openAudiencePurchasingBehaviourForm,
      closeForm: this.closeAudiencePurchasingBehaviourForm,
      characteristics: [
        'to be sensitive to ownership cost when buying automobiles',
        'to be influenced by product utility when making product purchases',
        'to be influenced by online ads when making product purchases',
        'to be influenced by social media when making product purchases',
        'to be influenced by brand name when making product purchases',
        'to be influenced by family when making product purchases',
        'to prefer using credit cards for shopping',
        'to indulge in spur of the moment purchases',
      ],
    });
  }

  renderAudienceLifestyleBehaviour = () => {
    const { audienceLifestyleBehaviourFormIsOpen } = this.state;

    const { values: { audience_lifestyle_behaviours } } = this.props;

    return this.renderAudienceCharacteristics({
      title: 'Lifestyle behaviours',
      name: 'audience_lifestyle_behaviours',
      preferences: audience_lifestyle_behaviours,
      formIsOpen: audienceLifestyleBehaviourFormIsOpen,
      openForm: this.openAudienceLifestyleBehaviourForm,
      closeForm: this.closeAudienceLifestyleBehaviourForm,
      characteristics: [
        'to read often',
        'to eat out frequently',
        'to have a gym membership',
        'to be concerned about the environment',
        'to consider starting a business in next few years',
        'to have experience playing music',
        'to attend live musical events',
        'to volunteer for social causes',
      ],
    });
  }

  renderAudience() {
    return (
      <>
        <div className="masonry-item  w-100">
          <h2>Audience</h2>
        </div>
        <div className="masonry-item w-100">
          <div className="row gap-20">
            <div className="col-md-12">
              <div className="bd bgc-white p-20 clearfix">

                {/*
                
                THERE IS CURRENTLY NO WAY TO FILTER INFLUENCER SEARCH RESULTS BY
                AUDIENCE DEMOGRAPHICS, AUDIENCE GEOGRAPHICS OR AUDIENCE INTERESTS

                */}

                {/* <div className="filtering-options clearfix">
                  <div className="option-section">
                    <p>DEMOGRAPHICS</p>
                  </div>

                  {this.renderAudienceAgeGroups()}

                  {this.renderAudienceGender()}

                </div> */}
                {/*
                <div className="filtering-options clearfix">
                  <div className="option-section">
                    <p>INTERESTS</p>
                  </div>

                  {this.renderAudienceCategoryInterests()}

                  {this.renderAudienceKeywordInterests()}

                </div>
                */}

                {/* <div className="filtering-options clearfix">
                  <div className="option-section">
                    <p>GEOGRAPHICS</p>
                  </div>

                  {this.renderAudienceLocations()}

                  {this.renderAudienceLanguages()}

                </div> */}

                <div className="filtering-options clearfix">
                  <div className="option-section">
                    <p>PREFERENCES</p>
                  </div>

                  {this.renderAudiencePurchasingPreferences()}

                  {this.renderAudienceLifestylePreferences()}

                </div>

                <div className="filtering-options clearfix">
                  <div className="option-section">
                    <p>BEHAVIOUR</p>
                  </div>

                  {this.renderAudiencePurchasingBehaviour()}

                  {this.renderAudienceLifestyleBehaviour()}

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderCampaign() {
    const { values, handleChange } = this.props;

    return (
      <>
        <div className="masonry-item  w-100">
          <h2>Campaign</h2>
        </div>
        <div className="masonry-item w-100">
          <div className="row gap-20">
            <div className="col-md-12">
              <div className="bd bgc-white p-20 clearfix">
                <div className="filtering-options">
                  <div className="option-content">
                    <p className="option-title">Your campaign goal</p>
                    <ul className="option-list removable-option">
                      <li className="dropdown-option">
                        <select
                          className="form-control d-inline w-auto"
                          value={values.campaign_goal}
                          name="campaign_goal"
                          onChange={handleChange}
                        >
                          <option value="awareness">Expand brand awareness</option>
                          <option value="conversions">Improve sales conversion</option>
                          <option value="engagement">Increase engagement</option>
                          <option value="superstar">Find the next Ninja</option>
                        </select>
                      </li>
                    </ul>
                  </div>
                  <div className="option-content">
                    <label className="option-title">Your campaign budget</label>
                    <ul className="option-list">
                      <li>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">USD</div>
                          </div>
                          <input
                            type="text"
                            name="campaign_budget"
                            value={values.campaign_budget}
                            placeholder="Amount"
                            onChange={handleChange}
                            className="form-control"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div>
        <Persist name="influencer-search-form" />

        <MenuDrawer />

        <div className="page-container">
          <TopBar />
          <main className="main-content bgc-grey-100">
            <form onSubmit={this.handleSubmit}>
              {this.renderSearch()}
              <div id="mainContent" className="main-form-content">
                <div className="row gap-20 masonry pos-r">
                  <div className="masonry-sizer col-md-6"></div>

                  {this.renderInfluencer()}

                  {this.renderAudience()}

                  {this.renderCampaign()}

                </div>
              </div>
            </form>
          </main>
        </div>
      </div>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import selectors from '../../../../store/selectors';

const mapStateToProps = state => ({ mentions: selectors.getAllMentions(state) });

const enhance = connect(mapStateToProps);

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MentionsTimelineChart = ({ mentions }) => {
  const mentionsByDate = mentions.sort(
    (mentionOne, mentionTwo) => mentionTwo.date - mentionOne.date
  );

  const numberOfMentionsByMonth = mentionsByDate
    .map(({ date }) => date * 1000)
    .reduce(
      (mentionsByMonth, mentionDate) => {
        const date = new Date(mentionDate);

        const monthIndex = date.getMonth();

        const month = months[monthIndex];

        const year = date.getFullYear();

        const monthOfTheYear = `${month} ${year}`;

        const count = mentionsByMonth[monthOfTheYear] ? mentionsByMonth[monthOfTheYear] + 1 : 1;

        return {
          ...mentionsByMonth,
          [monthOfTheYear]: count,
        };
      },
      {}
    );

  const data = Object.keys(numberOfMentionsByMonth)
    .map(
      month => ({
        month,
        mentions: numberOfMentionsByMonth[month],
      })
    )
    .reverse();

  return (
    <div style={{ height: 240, widht: '100%' }}>
      <ResponsiveContainer>
        <AreaChart
          margin={{ left: 0, right: 48 }}
          data={data}
        >
          <CartesianGrid />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Area
            dataKey="mentions"
            type="monotone"
            stroke="#2196f3"
            fill="#f3faf4"
            fillOpacity={0.6}
            dot={{ stroke: '#2196f3', strokeWidth: 2 }}
            />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

/*
 * this component performs some pretty heavy computations however relying
 * solely on the 'mentions' prop, React.memo is enough for now to make
 * sure this component doesn't waste any unnecessary re-computation
 * however the more mature way to optimize this would probably be
 * selector memoization with something like 'recompose'
 */
export default enhance(React.memo(MentionsTimelineChart));

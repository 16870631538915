import {
  all, call, fork, take, cancelled, put, cancel, takeEvery, takeLatest,
} from 'redux-saga/effects';
import {
  buffers, eventChannel,
} from 'redux-saga';

import { types, actions } from '../../../store/actions';
import Firebase from '../../resources/firebase';
import Dialog from '../Dialog';

function* watchFirebaseAuth() {
  let dataTask = null;
  const channel = yield call(instance => {
    return eventChannel(emit => {
      // act on every auth state changed
      instance.auth.onAuthStateChanged(authUser => {
        emit({ type: 'auth', payload: authUser });
      });
      return () => {
      };
    }, buffers.expanding(1));
  }, Firebase);
  while (true) {
    try {
      // waiting for events from Firebase
      const event = yield take(channel);
      Firebase.isLoaded = true;
      switch (event.type) {
        case 'auth':

          // user signout
          if (!event.payload) {
            yield put(actions.signout());
            // stop database watchers
            dataTask && (yield cancel(dataTask));
            dataTask = null;
          } else {
            // user signin or updated
            yield put(actions.userSignedIn(event.payload));

            // start database watchers
            if (!dataTask) dataTask = yield fork(watchFirebaseData, event.payload.uid);
          }
          break;
        default:
          break;
      }
    } catch (ex) {
      // handle error
    }
  }
}

function* watchFirebaseData(userUid) {
  const watchers = [
    {
      ref: Firebase.database.ref(`users/${userUid}/profile/displayName`),
      type: 'displayName',
    },
    {
      ref: Firebase.database.ref(`connections/${userUid}`),
      type: 'connections',
    },
    {
      ref: Firebase.database.ref(`templates/${userUid}`),
      type: 'templates',
    },
  ];
  const channel = yield call(() => {
    return eventChannel(emit => {
      // subscribe to data changes

      watchers.forEach(watcher => {
        watcher.ref.on('value', snapshot => {
          emit({ type: watcher.type, payload: snapshot });
        });
      });
      return () => {};
    });
  });
  try {
    // waiting for events from Firebase
    while (true) {
      const event = yield take(channel);
      switch (event.type) {
        case 'displayName':
          yield put(actions.userSignedIn({ displayName: event.payload.val() }));
          break;
        case 'connections':
          yield put(actions.bulkAddConnections(event.payload.val()));
          yield put(actions.updateProfiles());
          break;
        case 'templates':
          yield put(actions.bulkAddTemplates(event.payload.val()));
          break;
        default:
          break;
      }
    }
  } catch (ex) {
    // error handler
  } finally {
    // unsubscribe from db
    watchers.forEach(watcher => watcher.ref.off('value'));
    yield cancelled() && channel.close();
  }
}

function* signupWithPassword({ name, email, password }) {
  try {
    // add user to Firebase
    const result = yield call(Firebase.createUserWithCredentials, email, password);
    // add user to our own Firebase db with displayName & signin locally
    yield all([
      call(Firebase.addUser, result.user.uid, name, result.user.email, ''),
      put(actions.userSignedIn({
        uid: result.user.uid,
        displayName: name,
        email: result.user.email,
      })),
    ]);
  } catch (ex) {
    Dialog.show({
      caption: 'Failed',
      message: ex.message,
      buttons: ['Got it'],
    });
  } finally {
    yield put(actions.authInProgress());
  }
}

function* signinWithPassword({ email, password }) {
  try {
    // try to sign in with credentials provided
    yield call(Firebase.signInWithCredentials, email, password);
  } catch (ex) {
    Dialog.show({
      caption: 'Failed',
      message: ex.message,
      buttons: ['Got it'],
    });
  } finally {
    yield put(actions.authInProgress());
  }
}

function* signinGoogle() {
  try {
    // try to sign in with Google
    yield put(actions.authInProgress(true));
    const result = yield call(Firebase.signInWithGoogle);
    yield put(actions.userSignedIn({ accessToken: result.credential.accessToken }));
  } catch (ex) {
    Dialog.show({
      caption: 'Failed',
      message: ex.message,
      buttons: ['Got it'],
    });
  } finally {
    yield put(actions.authInProgress());
  }
}

function* signinFacebook() {
  try {
    // try to sign in with Facebook
    yield put(actions.authInProgress(true));
    const result = yield call(Firebase.signInWithFacebook);
    yield put(actions.userSignedIn({ accessToken: result.credential.accessToken }));
  } catch (ex) {
    Dialog.show({
      caption: 'Failed',
      message: ex.message,
      buttons: ['Got it'],
    });
  } finally {
    yield put(actions.authInProgress());
  }
}

function* resetPassword({ email }) {
  try {
    // requesting password reset link from Firebase
    yield all([
      put(actions.authInProgress(true)),
      call(Firebase.passwordReset, email),
    ]);
    Dialog.show({
      caption: 'Email has been sent!',
      message: 'Please check your email and follow the link to reset your password.',
      buttons: ['Got it'],
    });
  } catch (ex) {
    Dialog.show({
      caption: 'Failed',
      message: ex.message,
      buttons: ['Got it'],
    });
  } finally {
    yield put(actions.authInProgress());
  }
}

function* signout() {
  yield call(Firebase.signout);
  // compete clearence of redux state
  yield put(actions.resetApp());
}

function getBrandsAndCompetitorsForUser() {
  //var currentUserId = yield call(Firebase.getCurrentUserId); 
}

export default function* root() {
  yield all([
    watchFirebaseAuth(),
    takeLatest(types.SIGNUP_WITH_PASSWORD, signupWithPassword),
    takeLatest(types.SIGNIN_WITH_PASSWORD, signinWithPassword),
    takeLatest(types.SIGNIN_GOOGLE, signinGoogle),
    takeLatest(types.SIGNIN_FACEBOOK, signinFacebook),
    takeLatest(types.RESET_PASSWORD, resetPassword),
    takeEvery(types.SIGNOUT, signout),
  ]);
}

import React from 'react';
import { Link } from 'react-router-dom';

import * as paths from '../../constants/paths';
import logo from '../../../assets/images/logo.png';

export const MenuDrawer = () => (
  <div className="sidebar">
  <div className="sidebar-inner">
    <div className="sidebar-logo">
      <div className="peers ai-c fxw-nw">
        <div className="peer peer-greed">
          <Link to={paths.PATH_ROOT} className="sidebar-link td-n">
            <div className="peers ai-c fxw-nw">
              <div className="peer">
                <div className="logo">
                  <img src={logo} />        
                </div>
              </div>
              <div className="peer peer-greed">
                <h5 className="lh-1 mB-0 logo-text">Aggero</h5>
              </div>
            </div>
          </Link>
        </div>
        <div className="peer">
          <div className="mobile-toggle sidebar-toggle" href="javascript:void(0);">
            <a className="td-n">
              <i className="ti-arrow-circle-left" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <ul className="sidebar-menu scrollable pos-r">
      <li className="nav-item dropdown open">
        <a className="dropdown-toggle" href="javascript:void(0);">
          <span className="icon-holder">
            <i className="ti-layout-list-thumb" />
          </span>
          <span className="title">Market Segment</span>
          <span className="arrow">
            <i className="ti-angle-right" />
          </span>
        </a>
        <ul className="dropdown-menu">
          <li>
          <Link to={paths.PATH_ROOT} className="sidebar-link">Brand</Link>
          </li>
          { /* <li>
            <a className="sidebar-link">Competitor 1</a>
          </li>
          <li>
            <a className="sidebar-link">Competitor 2</a>
          </li>
          <li>
            <a className="sidebar-link">Sector</a>
          </li>
         */}
        </ul>
      </li>
      <li className="nav-item">
        <Link className="sidebar-link" to={paths.INFLUENCER_SEARCH} >
          <span className="icon-holder">
            <i className="ti-user" />
          </span>
          <span className="title">Influencer Discovery</span>
        </Link>
      </li>
    </ul>
  </div>
</div>
  /*
  <div className="sidebar">
    <div className="sidebar-inner">
    <div className="sidebar-logo">
        <div className="peers ai-c fxw-nw">
          <div className="peer peer-greed">
            <a className="sidebar-link td-n">
              <div className="peers ai-c fxw-nw">
                <div className="peer">
                  <div className="logo">
                    <img src={logo} alt />
                  </div>
                </div>
                <div className="peer peer-greed">
                  <h5 className="lh-1 mB-0 logo-text">Aggero</h5>
                </div>
              </div>
            </a>
          </div>
          <div className="peer">
            <div className="mobile-toggle sidebar-toggle">
              <a href className="td-n">
                <i className="ti-arrow-circle-left" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ul className="sidebar-menu scrollable pos-r">
        <li className="nav-item mT-30 active">
          <Link className="sidebar-link" to={paths.PATH_ROOT}>
            <span className="icon-holder">
              <i className="c-blue-500 ti-home" />
            </span>
            <span className="title">Dashboard</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="sidebar-link" to={paths.PATH_CONNECTIONS}>
            <span className="icon-holder">
              <i className="c-brown-500 ti-settings" />
            </span>
            <span className="title">Connections</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="sidebar-link" to={paths.EMAIL_TEMPLATES}>
            <span className="icon-holder">
              <i className="c-blue-500 ti-share" />
            </span>
            <span className="title">Email templates</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="sidebar-link" to={paths.EMAIL_STATS}>
            <span className="icon-holder">
              <i className="c-deep-orange-500 ti-stats-up" />
            </span>
            <span className="title">Email stats</span>
          </Link>
        </li>
      </ul>
    </div>
  </div>
  */
);

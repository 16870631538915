import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import * as paths from '../../../../common/constants/paths';
import bgImage from '../../../../assets/images/bg.jpg';
import SigninForm from '../SigninForm';

export const SignInPage = ({
  signinWithPassword,
  signinGoogle,
  onSubmitFail,
  signinFacebook,
}) => {
  return (
    <div className="peers ai-s fxw-nw h-100vh">
      <div
        className="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
      </div>
      <div className="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r" style={{ minWidth: '320px' }}>
        <h4 className="fw-300 c-grey-900 mB-40">Login</h4>
        <SigninForm onSubmit={signinWithPassword} onSubmitFail={onSubmitFail} />
        <div className="peer">
          <Link to={paths.PATH_FORGOT}>Forgot your password?</Link>
        </div>
        <br />
        { /* 
        <button
          type="button"
          className="btn cur-p btn-primary"
          onClick={signinFacebook}
        >Login with Facebook</button><br />
        <button
          type="button"
          className="btn cur-p btn-danger"
          onClick={signinGoogle}
        >Login with Google</button><br />
                <Link to={paths.PATH_SIGNUP}>
          <button type="button" className="btn cur-p btn-secondary">No account? Register now!</button>
        </Link>
        */}

      </div>
    </div>
  );
};

SignInPage.propTypes = {
  signinWithPassword: PropTypes.func.isRequired,
  signinGoogle: PropTypes.func.isRequired,
  signinFacebook: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func.isRequired,
};

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { selectors } from '../../../store/selectors';
import { actions } from '../../../store/actions';

const mapStateToProps = state => ({
  dialog: selectors.getDialog(state),
});

const mapDispatchToProps = dispatch => ({
  onAccept: index => dispatch(actions.dialogAccept(index)),
  onCancel: () => dispatch(actions.dialogCancel()),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

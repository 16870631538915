import { connect } from 'react-redux';
import { withHandlers, compose } from 'recompose';

import { UserIsAuthenticated } from '../../../../common/auth';
import { actions } from '../../../../store/actions';

import {
  getInfluencerData,
  getvideoWordcloudData,
  getDateRangeFilter,
  getInfluencerNetworkFilter,
  getInfluencerYoutubeComments,
  getInfluencerTwitchComments,
  getInfluencerTwitchData,
  getInfluencerYoutubeData,
} from '../../selectors';
import mapSelectorsToProps from '../../../../helpers/map-selectors-to-props';

const mapStateToProps = mapSelectorsToProps({
  influencerData: getInfluencerData,
  influencerTwitchData: getInfluencerTwitchData,
  influencerYoutubeData: getInfluencerYoutubeData,
  videoWordcloudData: getvideoWordcloudData,
  dateRangeFilter: getDateRangeFilter,
  networkFilter: getInfluencerNetworkFilter,
  youtubeComments: getInfluencerYoutubeComments,
  twitchComments: getInfluencerTwitchComments,
})

const mapDispatchToProps = { dateRangeFilterChanged: actions.dateRangeFilterChanged };

export default compose(
  UserIsAuthenticated,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    getInfluencerData: ({ dispatch }) => influencerId => {
      dispatch(actions.requestInfluencerDetailsData(influencerId));
    },
  })
);

import {
  all, take, call, takeLatest, takeEvery, select, put,
} from 'redux-saga/effects';

import Firebase from '../../resources/firebase';
import { actions, types } from '../../../store/actions';
import { selectors } from '../../../store/selectors';
import * as fbConstants from '../../resources/firebase/constants';
import Dialog from '../Dialog';

function* addConnection({ connType, code }) {
  try {
    const result = yield call(Firebase.addConnection, connType, code);
    Dialog.show({
      caption: 'Success!',
      message: `New account: '${result.data.email}' has been successfully linked`,
      buttons: ['Got it'],
    });
  } catch (ex) {
    // error handler
  }
}

function* requestGmailLink() {
  try {
    const result = yield call(Firebase.getGoogleAuthLink);

    // TODO: look for possible popup solution instead of redirect

    result
    && result.data
    && result.data.url
    && (window.location = result.data.url);
  } catch (ex) {
    Dialog.show({
      caption: 'Failed to link new account',
      message: ex.message,
      buttons: ['Got it'],
    });
  }
}

function* requestFacebookLink() {
  try {
    // TODO: look for possible popup solution instead of redirect

    yield call(Firebase.getGoogleAuthLink);
  } catch (ex) {
    Dialog.show({
      caption: 'Failed to link new account',
      message: ex.message,
      buttons: ['Got it'],
    });
  }
}

function* watchRemoveConnection() {
  while (true) {
    try {
      const { connType, id } = yield take(types.REMOVE_CONNECTION);
      yield call(Firebase.removeConnection, connType, id);
    } catch (ex) {
      // error handler
    }
  }
}

function* updateProfiles() {
  try {
    const connections = yield select(selectors.getConnectionsWithoutProfiles);
    if (!connections.length) return;
    const result = yield call(Firebase.getUserProfiles, connections);
    if (!result.data.result === fbConstants.FIREBASE_SUCCESS) return;
    yield put(actions.profilesData(result.data.profiles));
  } catch (ex) {
    // error handler
  }
}

export default function* root() {
  yield all([
    watchRemoveConnection(),
    takeLatest(types.REQUEST_GMAIL_LINK, requestGmailLink),
    takeLatest(types.REQUEST_FACEBOOK_LINK, requestFacebookLink),
    takeLatest(types.UPDATE_PROFILES, updateProfiles),
    takeEvery(types.ADD_CONNECTION, addConnection),
  ]);
}

import React, { useState } from 'react';
import classNames from 'classnames';

import Popover from '../../../common/components/Popover';

const AddTermMenu = ({ terms, onSelect }) => {
  const [isOpen, setOpen] = useState(false);

  const open = () => { setOpen(true); };

  const close = () => { setOpen(false); };

  const menuClassName = classNames(
    'dropdown-menu',
    { show: isOpen }
  );

  return (
    <Popover
      open={isOpen}
      onClose={close}
      under
      align="left"
      content={(
        <div className={menuClassName}>
          {
            terms.map(
              term => (
                <div key={term} className="dropdown">
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => { onSelect(term); close(); }}
                    >
                    {term}
                  </button>
                </div>
              )
            )
          }
        </div>
      )}
    >
      <button
        onClick={open}
        className="btn btn-outline-primary option-add"
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <i className="ia fa-plus" />
      </button>
    </Popover>
  );
};

export default AddTermMenu;

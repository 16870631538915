import { createReducer, createActions } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const STORAGE_PREFIX = 'brandPage';

const GENDER_DISTRIBUTION_DONUGHT_SETTINGS = {
  labels: [
    'Female',
    'Male',
  ],
  datasets: [
    {
      data: [300, 50],
      backgroundColor: [
        '#FF977D',
        '#279EE0',
      ],
      hoverBackgroundColor: [
        '#FF977D',
        '#279EE0',
      ],
    },
  ],
};

/*
 * TODO: formatting of this data to fit the react-charts-js-2 doughnut
 * is a ui-concern, not a model-concern, even more specifically only a
 * doughnut rendering concern, should be encapsulated to that
 */
const AGE_DISTRIBUTION_DONUGHT_SETTINGS = {
  labels: [
    '12 - 18',
    '18-30',
    '30-50',
    '50+',
  ],
  datasets: [
    {
      data: [35, 45, 18, 2],
      backgroundColor: [
        '#6F9CEB',
        '#8CB0EF',
        '#A9C4F3',
        '#C5D7F7',
      ],
      hoverBackgroundColor: [
        '#6F9CEB',
        '#8CB0EF',
        '#A9C4F3',
        '#C5D7F7',
      ],
    },
  ],
};

const GAMING_COMUNITIES_SETTINGS = {
  labels: ['Reddit 1', 'Reddit 2', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: '',
      backgroundColor: '#279EE0',
      borderColor: '#279EE0',
      borderWidth: 1,
      hoverBackgroundColor: '#279EE0',
      hoverBorderColor: '#279EE0',
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ],
};

const INITIAL_STATE = {
  mentionsTimeAgoFilter: undefined,
  mentionsPlatformFilter: undefined,
  commentsSentimentFilter: undefined,
  allMentions: [],
  companyName: '',
  languageRanks: [],
  subredditRanks: [],
  genderDistributionData: GENDER_DISTRIBUTION_DONUGHT_SETTINGS,
  ageDistributionData: AGE_DISTRIBUTION_DONUGHT_SETTINGS,
  topCountries: [],
  positiveMentionsCount: 0,
  mentions: {
    noOfMentions: 'Loading',
    mentionsTimeline: {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'No of mentions',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: [65, 59, 80, 81, 56, 55, 40],
        },
      ],
    },
  },
  wordcloudData: [
    { text: 'Loading', value: 1000 },
  ],
  gamingCommunitiesMentions: GAMING_COMUNITIES_SETTINGS,
  comments: [],
  sentimentMeter: {
    sentimentValue: 100,
  },
  sentimentChartData: [
    {
      titleBar: 'Monday',
      values: [
        {
          title: 'Negative',
          value: 10,
        },
        {
          title: 'Slightly Negative',
          value: 40,
        },
        {
          title: 'Positive',
          value: 50,
        },
        {
          title: 'Slightly Positive',
          value: 70,
        },
      ],
    }],
};

export const requestServerData = (state) => {
  return state;
};

export const serverDataReceived = (
  state,
  action
) => {
  const {
    companyData: {
      companyData,
      tweets,
    },
  } = action

  return {
    ...state,

    tweets,
    allMentions: [ ...companyData.mentions, ...tweets ],
    topCountries: companyData.top_countries,
    languageRanks: companyData.language_ranks,
    subredditRanks: companyData.subreddit_ranks,
    wordcloudData: companyData.wordcloud,
    companyName: companyData.companyName,
    gamingCommunitiesMentions: transformGamingCommunitiesMentions(companyData.subreddit_ranks),
  };
};

export const handleMentionsFilterChanged = (state, action) => {
  const { payload: { value, name } } = action;

  return {
    ...state,
    [name]: value,
  };
};

export const handleCommentsSentimentFilterChanged = (state, action) => {
  const { payload: { sentiment } } = action;

  return {
    ...state,
    commentsSentimentFilter: sentiment,
  };
};

function transformGamingCommunitiesMentions(subredditRanks) {
  const gcm = GAMING_COMUNITIES_SETTINGS;
  const rankings = transformToTitleValue(subredditRanks);

  gcm.datasets[0].data = rankings.map(r => r.value);
  gcm.labels = rankings.map(r => r.title);
  return gcm;
}

function transformToTitleValue(topCountries) {
  return Object.keys(topCountries)
    .map(key => ({ title: key, value: topCountries[key] }))
    .sort(
      (c1, c2) => c2.value - c1.value)
    .slice(0, 5);
}

const { Types, Creators } = createActions(
  {
    requestServerData: ['companyName', 'companyFilters'],
    serverDataReceived: ['companyData'],
    filterComments: ['sentiment'],
    mentionFilterChanged: ['payload'],
    commentsSentimentFilterChanged: ['payload'],
  },
  {
    prefix: `${STORAGE_PREFIX}/`,
  }
);

export const HANDLERS = {
  [Types.REQUEST_SERVER_DATA]: requestServerData,
  [Types.SERVER_DATA_RECEIVED]: serverDataReceived,
  [Types.MENTION_FILTER_CHANGED]: handleMentionsFilterChanged,
  [Types.COMMENTS_SENTIMENT_FILTER_CHANGED]: handleCommentsSentimentFilterChanged,
};

const persistConfig = {
  key: STORAGE_PREFIX,
  storage,

  whitelist: [],
};

export const reducer = persistReducer(persistConfig, createReducer(INITIAL_STATE, HANDLERS));

export const actionTypes = Types;

export default Creators;

import React from 'react'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const Languages = props => {
  const { data } = props

  return (
    <div style={{ height: 240 }}>
      <ResponsiveContainer>
        <BarChart data={data}>
          <XAxis
            dataKey="language"
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            dataKey="members"
            axisLine={false}
            tickLine={false}
          />
          <Tooltip />
          <Bar
            barSize={20}
            dataKey="members"
            fill="#009fff"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default Languages

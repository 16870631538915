import { createSelector } from 'reselect';

import { STORAGE_PREFIX as prefix } from './redux';

const getState = state => state[prefix];

export const getInfluencerName = state => state[prefix].name;

export const getvideoWordcloudData = state => state[prefix].videoWordcloudData;

export const getInfluencerData = state => state[prefix].influencer || {};

export const getInfluencerTwitchData = state => state[prefix].twitch || {};

export const getInfluencerYoutubeComments = ({
  [ prefix ]: {
    youtubeComments: {
      comments,
    } = {},
  },
}) => comments

export const getInfluencerYoutubeData = createSelector(
  getState,
  state => state.youtube || {}
);

export const getInfluencerTweets = createSelector(
  getInfluencerData,
  ({ twitter }) => twitter || []
);

export const getInfluencerAudienceTweets = state => state[prefix].tweets

export const getInfluencerTwitchComments = createSelector(
  getInfluencerTwitchData,
  ({ comments }) => comments || []
)

export const getInfluencerNetworkFilter = state => state[prefix].network;

export const getInfluencerTotalTwitchFollowers = createSelector(
  getInfluencerData,
  ({ twitch }) => twitch && twitch.info_channel.total_followers
);

export const getInfluencerTotalYoutubeSubscribers = createSelector(
  getInfluencerYoutubeData,
  ({ channel_stats }) => channel_stats && channel_stats.subscriber_count
);

export const getDateRangeFilter = state => state[prefix].dateRangeFilter;

export const getInfluencerActivity = createSelector(
  getDateRangeFilter,
  getInfluencerData,
  (dateRangeFilter, influencer) => {
    const { twitch: { all_days_info } = {} } = influencer;

    if (!all_days_info) return;

    return all_days_info[dateRangeFilter / (1000 * 60 * 60 * 24)];
  }
);

export const getInfluencerType = createSelector(
  getInfluencerYoutubeData,
  youtube => {
    const { channel_stats } = youtube;

    if (!channel_stats) return 'N/A';

    if (channel_stats.subscriber_count >= 1000000) {
      return 'MEGA INFLUENCER';
    }

    if (
      channel_stats.subscriber_count < 1000000
      && channel_stats.subscriber_count >= 100000
    ) {
      return 'MACRO INFLUENCER';
    }

    if (
      channel_stats.subscriber_count < 100000
      && channel_stats.subscriber_count >= 10000
    ) {
      return 'MICRO INFLUENCER';
    }

    if (
      channel_stats.subscriber_count < 100000
      && channel_stats.subscriber_count >= 10000
    ) {
      return 'MICRO INFLUENCER';
    }

    return 'NANO INFLUENCER';
  }
);

export const getInfluencerSummary = createSelector(
  getInfluencerName,
  getInfluencerData,
  getInfluencerYoutubeData,
  getInfluencerType,
  (
    name,
    influencer,
    youtube,
    influencerType
  ) => {
    const { twitch } = influencer;
    const { channel_stats } = youtube;

    if (twitch) {
      const {
        info_channel: {
          display_name,
          size,
          profile_image_url,
          description,
          total_followers,
          login,
        },
        engagement_info: {
          last_game,
          language,
          content,
          rate,
        },
      } = twitch;

      return {
        language,
        description,
        content,
        name: display_name,
        type: size,
        profileImageUrl: profile_image_url,
        lastGamePlayed: last_game,
        estimatedRate: rate,
        totalFollowers: total_followers,
        link: `https://www.twitch.tv/${login}`,
      };
    }

    if (channel_stats) {
      const {
        country,
        profile_image_url,
        rate,
      } = channel_stats;

      return {
        ...channel_stats,
        name,
        type: influencerType,
        profileImageUrl: profile_image_url,
        estimatedRate: rate,
        location: country,
      };
    }
  }
);

export const getInfluencerYoutubeAudienceEngagement = createSelector(
  getInfluencerYoutubeData,
  youtube => {
    const { channel_stats = {} } = youtube;

    const {
      view_count,
      avg_view_count_per_video,
      comment_count,
      avg_comments_per_video,
      like_dislike_ratio,
      subscriber_count,
    } = channel_stats;

    return {
      viewCount: view_count,
      averageViewCountPerVideo: avg_view_count_per_video,
      commentCount: comment_count,
      averageCommentsPerVideo: avg_comments_per_video,
      likeDislikeRatio: like_dislike_ratio,
      subscriberCount: subscriber_count,
    };
  }
);
export const getInfluencerTwitchAudienceEngagement = createSelector(
  getInfluencerActivity,
  getInfluencerData,
  (
    influencerActivity = {},
    influencer = {}
  ) => {
    const { twitch: { engagement_info = {} } = {} } = influencer;

    const {
      views_days,
      avg_viewers_days,
      peak_viewers_days,
    } = influencerActivity;

    const {
      follower_virality,
      engagement_avg,
      engagement_peak,
    } = engagement_info;

    return {
      viewsDays: views_days,
      averageViewersDays: avg_viewers_days,
      peakViewersDays: peak_viewers_days,
      followerVirality: follower_virality,
      engagementAverage: engagement_avg,
      engagementPeak: engagement_peak,
    };
  }
);

export const getInfluencerWordcloud = createSelector(
  getInfluencerNetworkFilter,
  getInfluencerData,
  getInfluencerYoutubeData,
  (
    networkFilter,
    influencer,
    youtube
  ) => {
    const wordcloud = networkFilter === 'twitch'
      ? influencer.wordcloud
      : youtube.video_wordcloud;

    if (!wordcloud) return [];

    return Object.keys(wordcloud)
      .sort(
        (wordOne, wordTwo) => wordcloud[wordTwo] - wordcloud[wordOne]
      )
      .map(
        word => ({ text: word, value: wordcloud[word] })
      )
      .slice(0, 20);
  }
);

export const getTwitchAudienceGenderDistribution = createSelector(
  getInfluencerTwitchData,
  twitch => {
    const { comments } = twitch;

    if (!comments) return [];

    /*
     * only gender data currently available for an influencer's audience's
     * gender distribution comes in the from of an array of numbers inside
     * of an entity representing a twitch comment & its meta-data with one
     * entry representing the users assumed gender closer to 1 standing
     * for more likely female, closer to 0 standing for more likely female
     *
     * 07.07.2019
     */
    const { length: amountOfMaleAudienceMembers } = comments.filter(
      ({ gender }) => gender[0] < 0.5
    );

    return [
      {
        gender: 'male',
        members: amountOfMaleAudienceMembers,
      },
      {
        gender: 'female',
        members: comments.length - amountOfMaleAudienceMembers,
      },
    ];
  }
);

export const getYoutubeAudienceGenderDistribution = createSelector(
  getInfluencerYoutubeComments,
  youtubeComments => {
    if (!youtubeComments) return [];

    const { length: amountOfMaleAudienceMembers } = youtubeComments.filter(
      ({ gender }) => gender[0] < 0.5
    );

    return [
      {
        gender: 'male',
        members: amountOfMaleAudienceMembers,
      },
      {
        gender: 'female',
        members: youtubeComments.length - amountOfMaleAudienceMembers,
      },
    ];
  }
)

export const getInfluencerAudienceMembersByLanguage = createSelector(
  getInfluencerTweets,
  tweets => {
    return tweets
      .map(
        ({ language }) => language
      )
      .filter(
        language => language !== 'und'
      )
      .reduce(
        (numberOfAudienceMembersByLanguage, language) => {
          const count = numberOfAudienceMembersByLanguage[language]
            ? numberOfAudienceMembersByLanguage[language] + 1
            : 1;

          return {
            ...numberOfAudienceMembersByLanguage,
            [language]: count,
          };
        },
        {}
      );
  }
);

export const getInfluencerValues = createSelector(
  getInfluencerTwitchData,
  ({ personality: { values } = {} }) => values
);

export const getInfluencerBehaviour = createSelector(
  getInfluencerNetworkFilter,
  getInfluencerTwitchData,
  getInfluencerYoutubeData,
  (
    networkFilter,
    twitch,
    youtube
  ) => {
    if (networkFilter === 'twitch') {
      return twitch.behaviour
    }

    return youtube.behaviour
  }
);

import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import storage from 'redux-persist/lib/storage';
import locationReducer from './location';

export default () => {
  const context = require.context('..', true, /\.\/(common|features).*\/redux\.js$/);
  const projectReducers = {};
  const storagePrefixes = [];
  context.keys().forEach(path => {
    const storePrefix = context(path).STORAGE_PREFIX;
    storagePrefixes.push(storePrefix);
    projectReducers[storePrefix] = context(path).reducer;
  });
  const appReducer = combineReducers({
    ...projectReducers,
    form,
    location: locationReducer,
  });
  return (state, action) => {
    if (action.type === 'RESET_APP') {
      storagePrefixes.forEach(
        prefix => storage.removeItem(`persist:${prefix}`)
      );
      state = undefined;
    }
    return appReducer(state, action);
  };
};

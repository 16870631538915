import { STORAGE_PREFIX as prefix } from './redux';
import * as constants from './constants';

export const getConnectedGmailAccounts = state => state[prefix].connectedGmailAccounts;
export const getConnectedFacebookAccounts = state => state[prefix].connectedFacebookAccounts;
export const getAuthFacebook = state => state[prefix].authFacebook;
export const getConnectionsWithoutProfiles = state => {
  const res = [];
  for (const connection of state[prefix].connectedGmailAccounts) {
    connection.name === constants.LOADING_NAME
    && res.push({ connType: constants.TYPE_GOOGLE, id: connection.id });
  }
  for (const connection of state[prefix].connectedFacebookAccounts) {
    connection.name === constants.LOADING_NAME
    && res.push({ connType: constants.TYPE_FACEBOOK, id: connection.id });
  }
  return res;
};
export const getConnectionGoogleById = (state, template) => {
  if (!template) return constants.PROFILE_LOADING;
  const connection = state[prefix].connectedGmailAccounts.find(
    item => item.id === template.connectedGmailAccountId
  );
  if (!connection) return constants.PROFILE_LOADING;
  return {
    name: connection.name,
    email: connection.email,
    image: connection.picture,
  };
};

import { createSelector } from 'reselect'

import { STORAGE_PREFIX as prefix } from './redux'

const getState = ({ [ prefix ]: state }) => state

export const getAllMentions = state => {
  const {
    allMentions: mentions,
    mentionsTimeAgoFilter,
    mentionsPlatformFilter,
  } = state[prefix];

  return mentions.filter(
    mention => {
      const matchesTimeAgo = (mention.date * 1000) > (Date.now() - mentionsTimeAgoFilter);

      const matchesPlatform = mention.type === mentionsPlatformFilter;

      if (mentionsTimeAgoFilter && !matchesTimeAgo) return false;

      if (mentionsPlatformFilter && !matchesPlatform) return false;

      return true;
    }
  );
};

export const getComments = (state) => {
  const { commentsSentimentFilter } = state[prefix];

  const comments = getAllMentions(state)
    .sort(
      (commentOne, commentTwo) => commentTwo.date - commentOne.date
    )
    .map(
      comment => (
        {
          name: comment.author,
          time: new Date(comment.date * 1000).toString(),
          comment: comment.textFragment,
          source: comment.type,
          sentimentGroup: comment.sentimentGroup,
        }
      )
    );

  if (!commentsSentimentFilter) {
    return comments;
  }

  return comments.filter(
    comment => comment.sentimentGroup === commentsSentimentFilter
  );
};

export const getSentimentMeterData = state => {
  const mentions = getAllMentions(state);

  const mentionsWithSentimentData = mentions.filter(
    ({ sentiment }) => Boolean(sentiment) || sentiment === 0
  )

  return mentionsWithSentimentData.reduce(
    (sum, { sentiment }) => isNaN(sentiment) ? sum : sum + sentiment,
    0
  ) / mentionsWithSentimentData.length
}

export const getMentions = state => {
  const mentions = getAllMentions(state);

  const mentionsResult = {};
  mentionsResult.noOfMentions = mentions.length;
  mentionsResult.mentionsTimeline = {};
  mentionsResult.mentionsTimeline.labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  mentionsResult.mentionsTimeline.datasets = [
    {
      label: 'No of mentions',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];

  return mentionsResult;
};

export const getPositiveMentionsCount = state => {
  return getAllMentions(state).filter(mention => mention.sentimentGroup === 'very positive').length;
};

export const getSlightlyPositiveMentionsCount = state => {
  return getAllMentions(state).filter(mention => mention.sentimentGroup === 'slightly positive').length;
};

export const getSlightlyNegativeMentionsCount = state => {
  return getAllMentions(state).filter(mention => mention.sentimentGroup === 'slightly negative').length;
};

export const getNegativeMentionsCount = state => {
  return getAllMentions(state).filter(mention => mention.sentimentGroup === 'very negative').length;
};

export const getSentimentChartData = state => state[prefix].sentimentChartData;

export const getWordcloudData = state => {
  const { wordcloudData } = state[prefix];

  const wordCloudArrayResult = [];

  for (const property in wordcloudData) {
    if (wordcloudData.hasOwnProperty(property)) {
      wordCloudArrayResult.push({ text: property, value: wordcloudData[property] });
    }
  }

  return wordCloudArrayResult.slice(0, 20);
};

export const getGamingCommunitiesMentions = state => state[prefix].gamingCommunitiesMentions;

const GENDER_DISTRIBUTION_DOUGHNUT_SETTINGS = {
  labels: [
    'Female',
    'Male',
  ],
  datasets: [
    {
      data: [300, 50],
      backgroundColor: [
        '#FF977D',
        '#279EE0',
      ],
      hoverBackgroundColor: [
        '#FF977D',
        '#279EE0',
      ],
    },
  ],
};

export const getBrandTweets = createSelector(
  getState,
  ({ tweets }) => tweets
)

export const getGenderDistributionData = state => {
  const mentions = getAllMentions(state);

  const genders = mentions.map(
    ({ gender }) => gender[0] || gender
  )

  const femaleAudienceMembers = genders.filter(
    gender => gender > 0.5
  );

  const genderDistribution = GENDER_DISTRIBUTION_DOUGHNUT_SETTINGS;

  genderDistribution.datasets[0].data = [
    femaleAudienceMembers.length,
    genders.length - femaleAudienceMembers.length,
  ];

  return genderDistribution;
};

export const getCompanyName = state => state[prefix].companyName;

export const getTopGamingCommunitiesMentions = state => {
  const { subredditRanks } = state[prefix];

  return Object.keys(subredditRanks)
    .map(
      subreddit => ({ subreddit, mentions: subredditRanks[subreddit] })
    )
    .sort(
      (subredditRankOne, subredditRankTwo) => subredditRankTwo.mentions - subredditRankOne.mentions
    );
};

export const getTopLanguages = state => {
  const { languageRanks } = state[prefix];

  return Object.keys(languageRanks)
    .map(
      language => ({ language, value: languageRanks[language] })
    )
    .sort(
      (languageOne, languageTwo) => languageTwo.value - languageOne.value
    );
};

export const getTopCountries = state => {
  const { topCountries } = state[prefix];

  /*
   * the data-structure of these topCountries is this:
   * { countryName: number }
   *
   * not sure what that number respresents, could be mentions
   * gonna leave it at just 'value' for now
   */
  return Object.keys(topCountries)
    .map(
      country => ({ name: country, value: topCountries[country] })
    )
    .sort(
      (countryOne, countryTwo) => countryTwo.value - countryOne.value
    );
};

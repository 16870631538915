import React from 'react'
import { connect } from 'react-redux'
import { Doughnut } from 'react-chartjs-2';

import mapSelectorsToProps from '../../../../helpers/map-selectors-to-props';
import between from '../../../../utils/between';
import percent from '../../../../utils/percent';
import sum from '../../../../utils/sum';
import { getBrandTweets } from '../../selectors'

const mapStateToProps = mapSelectorsToProps({ tweets: getBrandTweets })

const enhance = connect(mapStateToProps)

const BrandPageAudienceAgeGroup = props => {
  const { tweets = [] } = props

  const audienceMembersByAgeGroup = tweets.reduce(
    (reduction, tweet) => {
      const { age } = tweet

      if (!age) return reduction

      let ageGroup

      if (between(12, 17)(age)) {
        ageGroup = '12-18'
      } else if (between(18, 24)(age)) {
        ageGroup = '18-24'
      } else if (between(25, 34)(age)) {
        ageGroup = '25-34'
      } else if (between(35, 44)(age)) {
        ageGroup = '35-44'
      } else if (between(45, 54)(age)) {
        ageGroup = '45-54'
      } else if (between(55, 64)(age)) {
        ageGroup = '55-64'
      } else if (age > 64) {
        ageGroup = '65+'
      }

      if (!ageGroup) return reduction

      return {
        ...reduction,
        [ageGroup]: reduction[ageGroup] + 1,
      }
    },
    {
      '12-17': 0,
      '18-24': 0,
      '25-34': 0,
      '35-44': 0,
      '45-54': 0,
      '55-64': 0,
      '65+': 0,
    }
  )

  return (
    <Doughnut
      data={{
        labels: Object.keys(audienceMembersByAgeGroup),
        datasets: [
          {
            data: Object.values(audienceMembersByAgeGroup),
            backgroundColor: [
              '#174EA8',
              '#1E5FCA',
              '#3677E2',
              '#6194E9',
              '#8CB0EF',
              '#C3D6F7',
              '#E3EAF7',
            ],
            hoverBackgroundColor: [
              '#174EA8',
              '#1E5FCA',
              '#3677E2',
              '#6194E9',
              '#8CB0EF',
              '#C3D6F7',
              '#E3EAF7',
            ],
          },
        ],
      }}
      options={{
        tooltips: {
          callbacks: {
            label: ({ index }, { labels, datasets }) => {
              const total = sum(datasets[0].data);

              return `${labels[index]}: ${percent(total, datasets[0].data[index])}%`;
            },
          },
        },
      }}
    />
  )
}

export default enhance(BrandPageAudienceAgeGroup)

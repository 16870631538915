import React, { memo } from 'react'

const enhance = memo

const Sentiments = props => {
  const { mentions } = props

  const { length: amountOfVeryPositiveMentions } = mentions.filter(({ sentimentGroup }) => sentimentGroup === 'very positive')

  const { length: amountOfSlightlyPositiveMentions } = mentions.filter(({ sentimentGroup }) => sentimentGroup === 'slightly positive')

  const { length: amountOfSlightlyNegativeMentions } = mentions.filter(({ sentimentGroup }) => sentimentGroup === 'slightly negative')

  const { length: amountOfVeryNegativeMentions } = mentions.filter(({ sentimentGroup }) => sentimentGroup === 'very negative')

  return (
    <ul className="p-20 nav flex-column w-100">
      <li className="nav-item pT-15 pB-15">
        <div className="peers ai-c jc-sb">
          <div className="peer peer-greed">
            <span className="sentiment-list-dot sentiment-p" />
            <span className="list-item-left">Positive mentions</span>
          </div>
          <div className="peer">
            <i className="ti-comment-alt" />
            <span className="list-item-right">{amountOfVeryPositiveMentions}</span>
          </div>
        </div>
      </li>
      <li className="nav-item pT-15 pB-15">
        <div className="peers ai-c jc-sb">
          <div className="peer peer-greed">
            <span className="sentiment-list-dot sentiment-sp" />
            <span className="list-item-left">Slightly positive mentions</span>
          </div>
          <div className="peer">
            <i className="ti-comment-alt" />
            <span className="list-item-right">{amountOfSlightlyPositiveMentions}</span>
          </div>
        </div>
      </li>
      <li className="nav-item pT-15 pB-15">
        <div className="peers ai-c jc-sb">
          <div className="peer peer-greed">
            <span className="sentiment-list-dot sentiment-sn" />
            <span className="list-item-left">Slightly negative mentions</span>
          </div>
          <div className="peer">
            <i className="ti-comment-alt" />
            <span className="list-item-right"> {amountOfSlightlyNegativeMentions}</span>
          </div>
        </div>
      </li>
      <li className="nav-item pT-15 pB-15">
        <div className="peers ai-c jc-sb">
          <div className="peer peer-greed">
            <span className="sentiment-list-dot sentiment-n" />
            <span className="list-item-left">Negative mentions</span>
          </div>
          <div className="peer">
            <i className="ti-comment-alt" />
            <span className="list-item-right"> {amountOfVeryNegativeMentions}</span>
          </div>
        </div>
      </li>
    </ul>
  )
}

export default enhance(Sentiments)

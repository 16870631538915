import { createActions } from 'reduxsauce';

export const STORAGE_PREFIX = 'influencerDetailPage';

const INITIAL_STATE = {
  dateRangeFilter: 1000 * 60 * 60 * 24 * 365,
  youtube: undefined,
  twitch: undefined,
  influencer: undefined,
  videoWordcloudData: [],
};

const { Types, Creators } = createActions(
  {
    requestInfluencerDetailsData: ['payload'],
    influencerDetailsDataReceived: ['payload'],
    dateRangeFilterChanged: ['payload'],
  },
  {
    prefix: `${STORAGE_PREFIX}/`,
  }
);

export const handleDateRangeFilterChanged = (state, { payload }) => {
  return { ...state, dateRangeFilter: payload };
};

export const handleRequestInfluencerDetailsData = (
  state,
  {
    payload: {
      influencerId,
      influencerNetwork,
    },
  }
) => {
  return {
    ...state,
    name: influencerId,
    network: influencerNetwork,
  };
};

export const handleInfluencerDetailsDataReceived = (
  state,
  {
    payload: {
      influencer,
      youtube,
      youtubeComments,
      twitch,
      tweets,
    },
  }
) => ({
  ...state,
  influencer,
  youtube,
  youtubeComments,
  twitch,
  tweets,
});

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.DATE_RANGE_FILTER_CHANGED: {
      return handleDateRangeFilterChanged(state, action);
    }

    case Types.REQUEST_INFLUENCER_DETAILS_DATA: {
      return handleRequestInfluencerDetailsData(state, action);
    }

    case Types.INFLUENCER_DETAILS_DATA_RECEIVED: {
      return handleInfluencerDetailsDataReceived(state, action);
    }

    default:
      return state;
  }
};

export const actionTypes = Types;

export default Creators;

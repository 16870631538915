import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";

const SentimentMeter = ({value}) => {
  return (
    <ReactSpeedometer segments={4} maxSegmentLabels={0} minValue = {-1} maxValue = {1} startColor="#ED675E" endColor="#85CE88" value={value}/>
  );
};

export default SentimentMeter;

import { buffers, eventChannel } from 'redux-saga';

import Dispatcher from '../../classes/dispatcher';

class DialogClass extends Dispatcher {
  show(payload) {
    return new Promise(res => {
      this.dispatch('show', { payload: { ...payload, resolve: res } });
    });
  }
}

export const createAlertsChannel = () => {
  return eventChannel(emit => {
    const eventHandler = e => {
      emit(e.payload);
    };
    Dialog.on('show', eventHandler);
    return () => {
      Dialog.off('show', eventHandler);
    };
  }, buffers.expanding(1));
};

const Dialog = new DialogClass();
export default Dialog;

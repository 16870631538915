function through (beginning, end) {
  const array = []

  for (let i = beginning; i <= end; i++) {
    array.push(i)
  }

  return array
}

export default through

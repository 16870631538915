import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { InputField } from '../../../../common/components';

const SigninForm = ({
  pristine,
  authInProgress,
  handleSubmit,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field placeholder="required" name="email" type="email" component={InputField} label="Email"/>
      <Field placeholder="required" name="password" type="password" component={InputField} label="Password"/>
      <div className="form-group">
        <div className="peers ai-c jc-sb fxw-nw">
          <div className="peer">
            <div className="checkbox checkbox-circle checkbox-info peers ai-c">
              <input type="checkbox" id="inputCall1" name="inputCheckboxesCall" className="peer" />
              <label htmlFor="inputCall1" className=" peers peer-greed js-sb ai-c">
                <span className="peer peer-greed">Remember Me</span>
              </label>
            </div>
          </div>
          <div className="peer">
            <button className="btn btn-primary" disabled={pristine || authInProgress}>
              {authInProgress ? 'Loading' : 'Login'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

SigninForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  authInProgress: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SigninForm;

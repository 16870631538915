import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";

import average from '../utils/average';

const SentimentMeter = props => {
  const { mentions } = props

  const averageSentiment = average(
    mentions.map(
      ({ sentiment }) => sentiment
    )
  )

  return (
    <ReactSpeedometer
      segments={4}
      maxSegmentLabels={0}
      minValue={-1}
      maxValue={1}
      startColor="#ED675E"
      endColor="#85CE88"
      value={averageSentiment}
    />
  )
}

export default SentimentMeter

/*
* function => object => object
*
* filters an object given a checker function and kicks out any
* property-value pairs that do not pass the test
* */

export function objectFilter (checker) {
  return function (object) {
    return Object.keys(object).reduce(
      (reduction, key) => {
        const passes = checker(object[ key ], key)

        if (!passes) return reduction

        return { ...reduction, [ key ]: object[ key ] }
      },
      {}
    )
  }
}

export default objectFilter

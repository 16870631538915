import {
  all,
  call,
  takeLatest,
  put,
  select,
} from 'redux-saga/effects';
import Firebase from '../../common/resources/firebase';
import { types, actions } from '../../store/actions';
import { Dialog } from '../../common/containers';
import { selectors } from '../../store/selectors';

function* getBrandPageData() {
  try {
    const userEmail = yield select(selectors.getUserEmail);

    const result = yield call(Firebase.getBrandPageData, userEmail);
    const twitter_result = yield call(Firebase.getBrandTweets, userEmail);

    yield put(actions.serverDataReceived({ companyData: result, tweets: twitter_result }));
  } catch (ex) {
    Dialog.show({
      caption: 'Failed to get brand page data',
      message: ex.message,
      buttons: ['Got it'],
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(types.REQUEST_SERVER_DATA, getBrandPageData),
  ]);
}

import React from 'react';
import { connect } from 'react-redux';

import { getInfluencerSummary } from '../../selectors';

const mapStateToProps = state => ({ influencer: getInfluencerSummary(state) || {} });

const enhance = connect(mapStateToProps);

const InfluencerSummary = props => {
  const {
    influencer: {
      profileImageUrl,
      name,
      type,
      description,
      lastGamePlayed,
      language,
      estimatedRate,
      content,
      location,
      link,
    },
  } = props;

  return (
    <div className="masonry-item w-100">
      <div className="row gap-20">
        <div className="col-md-12">
          <div className="layers bd bgc-white p-20">
            <div className="row w-100">
              <div className="col-md-9">
                <div className="influencer-avatar-holder">
                  <img
                    src={profileImageUrl || 'https://static-cdn.jtvnw.net/jtv_user_pictures/cef31105-8a6e-4211-a74b-2f0bbd9791fb-profile_image-300x300.png'}
                    alt="influencer avatar"
                  />
                </div>
                <div className="influencer-bio">
                  {
                    name && (
                      <p className="influencer-name">
                        {name}
                        {
                          true && (
                            <a
                              href={link}
                              class="link-button"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i class="ti-link" />
                            </a>
                          )
                        }
                      </p>
                    )
                  }
                  {
                    type && <p className="influencer-type">{type}</p>
                  }
                  {
                    description && <p className="influencer-description">{description}</p>
                  }
                  <ul className="profile-desc-list">
                    {
                      lastGamePlayed && (
                        <li>
                          <p className="list-title">Last played game:</p>
                          <p className="list-content">{lastGamePlayed}</p>
                        </li>
                      )
                    }
                    {
                      language && (
                        <li>
                          <p className="list-title">Language:</p>
                          <p className="list-content">{language}</p>
                        </li>
                      )
                    }
                    {
                      location && (
                        <li>
                          <p className="list-title">Location:</p>
                          <p className="list-content">{location}</p>
                        </li>
                      )
                    }
                    {
                      content && (
                        <li>
                          <p className="list-title">Content:</p>
                          <p className="list-content">{content}</p>
                        </li>
                      )
                    }
                    {
                      estimatedRate && (
                        <li>
                          <p className="list-title">Estimated rate:</p>
                          <p className="list-content">${estimatedRate}</p>
                        </li>
                      )
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/*
<div className="col-md-3 influencer-score-col">
  <div className="score-container text-right mt-16">
    <p className="influencer-score-text" hidden={!matchingScore}>Matching Score</p>
    <p className="influencer-score">{matchingScore}</p>
  </div>

  <div className="score-container text-right mt-16" hidden={!growthScore}>
    <p className="influencer-score-text">Growth Score</p>
    <p className="influencer-score secondary-color">{growthScore}</p>
  </div>
</div>
*/

export default enhance(InfluencerSummary);

import React, { useState } from 'react'
import ReactList from 'react-list'
import Moment from 'react-moment'

function Comments (props) {
  const [sentimentFilter, setSentimentFilter] = useState('')

  function handleSentimentFilterSelection({ target: { value } }) {
    setSentimentFilter(value)
  }

  let { comments } = props

  if (sentimentFilter) {
    comments = props.comments.filter(
      ({ sentimentGroup }) => sentimentGroup === sentimentFilter
    )
  }

  function renderComment(index, key) {
    const {
      [index]: {
        picURL,
        name,
        time,
        source,
        text,
      },
    } = comments

    return (
      <div
        key={key}
        className="email-list-item peers fxw-nw p-20 bdB bgcH-grey-100 cur-p"
      >
        <div className="peer mR-10">
          <img
            src={picURL}
            className="w-3r h-3r bdrs-50p"
            hidden={!picURL}
            alt="comment poster avatar"
          />
        </div>
        <div className="peer peer-greed ov-h">
          <div className="peers ai-c">
            <div className="peer peer-greed">
              <h6>
                {name}
                {
                  name && time && ' - '
                }
                {
                  time && (
                    <small>
                      <Moment fromNow ago>{time}</Moment> ago
                    </small>
                  )
                }
              </h6>
            </div>
            <div className="peer">
              <small>{source}</small>
            </div>
          </div>
          <span className="w-100 ov-h tov-e d-b">
            {text}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="peers fxw-nw@lg+ ai-s">
      <div className="peer peer-greed w-50p@lg+ w-100@lg- p-20">
        <div className="layers">
          <div className="layer w-100 mB-10">
            <h6 className="lh-1 float-left">TOP COMMENTS</h6>
            <form className="float-right">
              <div className="form-group">
                <label
                  className="d-inline mr-10"
                  htmlFor="exampleFormControlSelect1"
                >
                  Sentiment
                </label>
                <select
                  className="form-control d-inline w-auto"
                  value={sentimentFilter}
                  onChange={handleSentimentFilterSelection}
                >
                  <option value="">Any</option>
                  <option value="very positive">Positive</option>
                  <option value="slightly positive">Slightly positive</option>
                  <option value="slightly negative">Slightly negative</option>
                  <option value="very negative">Negative</option>
                </select>
              </div>
            </form>
          </div>
          <div className="email-wrapper row bgc-white ov-h w-100">
            <div style={{ maxHeight: 600, overflow: 'auto', width: '100%' }} >
              <ReactList
                itemRenderer={renderComment}
                length={comments.length}
                type='variable'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comments 

import 'bootstrap';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './common/containers/App/App';
import './assets/styles/index.css';
import './assets/styles/index.scss';
import './assets/scripts/index.js';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

serviceWorker.unregister();

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as paths from '../common/constants/paths';
import {
  Signin,
  Signup,
  Forgot,
  NotFound,
} from '../common/components';
import {
  BrandPage,
  InfluencerSearchPage,
  Influencers,
  Influencer,
} from '../features';

const renderedRoutes = () => {
  return (
    <Switch>
      <Route path={paths.PATH_ROOT} exact component={BrandPage} />
      <Route path={paths.PATH_SIGNIN} component={Signin} />
      <Route path={paths.PATH_SIGNUP} component={Signup} />
      <Route path={paths.PATH_FORGOT} component={Forgot} />
      {/* <Route path={paths.PATH_CONNECTIONS} component={Connections} /> */}
      {/* <Route path={paths.EMAIL_TEMPLATES} component={EmailTemplates} /> */}
      {/* <Route path={paths.EMAIL_STATS} component={EmailStats} /> */}
      <Route path={paths.INFLUENCER_SEARCH} component={InfluencerSearchPage} />
      <Route path={paths.INFLUENCERS} component={Influencers} />
      <Route path={`${paths.INFLUENCER}/:influencerId/:influencerNetwork`} component={Influencer} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default renderedRoutes;

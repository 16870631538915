import { all, call, takeLatest, put, fork } from 'redux-saga/effects';

import firebase from '../../common/resources/firebase';
import { types, actions } from '../../store/actions';
import { Dialog } from '../../common/containers';

function* requestInfluencerData({ payload: { influencerId } }) {
  try {
    const [
      influencer,
      youtube,
      youtubeComments,
      twitch,
      tweets,
    ] = yield call(
      () => Promise.all(
        [
          firebase.getInfluencerById(influencerId),
          firebase.getYoutubeDataByInfluencerId(influencerId),
          firebase.getYoutubeCommentsByInfluencerId(influencerId),
          firebase.getTwitchChatByInfluencerId(influencerId),
          firebase.getInfluencerMentionTweetsByInfluencerId(influencerId),
        ]
      )
    )

    yield put(
      actions.influencerDetailsDataReceived(
        {
          influencer,
          youtube,
          youtubeComments,
          twitch,
          tweets,
        }
      )
    )
  } catch (e) {
    Dialog.show({
      caption: 'Failed to get influencer details data',
      message: e.message,
      buttons: ['Got it'],
    });

    throw e;
  }
}

function* watchRequestInfluencerData () {
  yield takeLatest(types.REQUEST_INFLUENCER_DETAILS_DATA, requestInfluencerData)
}

export default function* root() {
  yield all([
    fork(watchRequestInfluencerData),
  ])
}

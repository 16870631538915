import { connect } from 'react-redux';
import { withHandlers, compose } from 'recompose';

import { selectors } from '../../../../store/selectors';
import { UserIsAuthenticated } from '../../../../common/auth';
import { actions } from '../../../../store/actions';

const mapStateToProps = state => ({
  mentions: selectors.getMentions(state),
  comments: selectors.getComments(state),
  positiveMentionsCount: selectors.getPositiveMentionsCount(state),
  slightlyPositiveMentionsCount: selectors.getSlightlyPositiveMentionsCount(state),
  slightlyNegativeMentionsCount: selectors.getSlightlyNegativeMentionsCount(state),
  negativeMentionsCount: selectors.getNegativeMentionsCount(state),
  sentimentMeterData: selectors.getSentimentMeterData(state),
  sentimentChartData: selectors.getSentimentChartData(state),
  wordcloudData: selectors.getWordcloudData(state),
  gamingCommunitiesMentions: selectors.getGamingCommunitiesMentions(state),
  genderDistributionData: selectors.getGenderDistributionData(state),
  companyName: selectors.getCompanyName(state),
});

const mapDispatchToProps = {
  changeCommentsSentimentFilter: actions.commentsSentimentFilterChanged,
};

export default compose(
  UserIsAuthenticated,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({

    onLoadToCall: ({ dispatch }) => async () => {
      dispatch(
        actions.requestServerData()
      );
    },
  })
);

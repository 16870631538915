import PropTypes from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { validateEmail } from '../../../../common/utils';
import { FORM_FORGOT } from '../../../../common/constants/forms';
import { selectors } from '../../../../store/selectors';

const mapStateToProps = state => ({
  authInProgress: selectors.getAuthInProgress(state),
});

export default compose(
  connect(
    mapStateToProps
  ),
  setPropTypes({
    onSubmit: PropTypes.func.isRequired,
  }),
  reduxForm({
    form: FORM_FORGOT,
    validate: values => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Required';
      } else if (!validateEmail(values.email)) {
        errors.email = 'Invalid email address';
      }
      return errors;
    },
  })
);

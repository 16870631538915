import React, { useEffect } from 'react';

import { MenuDrawer, TopBar } from '../../../../common/components';

import GoBack from '../../../../components/GoBack';
import Traits from '../../../../components/Traits';
import Sentiments from '../../../../components/Sentiments';
import SentimentMeter from '../../../../components/SentimentMeter';
import InfluencerSummary from './InfluencerSummary';
import InfluencerAudienceReach from './InfluencerAudienceReach';
import InfluencerAudienceEngagement from './InfluencerAudienceEngagement';
import InfluencerActivity from './InfluencerActivity';
import InfluencerBehaviour from './InfluencerBehaviour';
import InfluencerAudienceComments from './InfluencerAudienceComments';
import InfluencerAudienceDemographics from './InfluencerAudienceDemographics';

export const Influencer = props => {
  const {
    dateRangeFilterChanged,
    dateRangeFilter,
    networkFilter,
    youtubeComments,
    twitchComments,

    getInfluencerData,
  } = props;

  useEffect(
    () => {
      const {
        match: {
          params: {
            influencerId = 19571641.0,
            influencerNetwork = 'twitch',
          },
        },
      } = props;

      getInfluencerData({
        influencerId,
        influencerNetwork,
      });
    },
    []
  );

  function setDateRangeFilter({ target: { value } }) {
    dateRangeFilterChanged(value);
  }

  function renderAudienceDemographics() {
    return (
      <InfluencerAudienceDemographics network={networkFilter} />
    )
  }

  function renderAudienceOpinions() {
    let comments

    if (networkFilter === 'twitch') {
      comments = twitchComments
    } else {
      comments = youtubeComments
    }

    if (!comments || comments.length === 0) return null

    return (
      <>
        <div className="masonry-item w-100">
          <h2>Audience Opinions</h2>
        </div>

        <div className="masonry-item w-100">
          <div className="bd bgc-white">
            <div className="row">
              <div className="col-12 col-md-6 border-right">
                <InfluencerAudienceComments network={networkFilter} />
              </div>
              <div className="col-12 col-md-6">
                <div className="peers fxw-nw@lg+ ai-s">
                  <div className="peer peer-greed w-30p@lg+ w-100@lg- p-20">
                    <div className="layers">
                      <div className="layer w-100 mB-10">
                        <h6 className="lh-1">OVERALL SENTIMENT</h6>
                      </div>
                      <div className="layer w-100 text-center">
                        <SentimentMeter mentions={comments} />
                      </div>
                      <Sentiments mentions={comments} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  function renderInfluencerPersonality() {
    const { influencerTwitchData, influencerYoutubeData } = props

    let data

    if (networkFilter === 'twitch') {
      data = influencerTwitchData && influencerTwitchData.personality
    } else {
      data = influencerYoutubeData && influencerYoutubeData.personality
    }

    if (!data) return null

    const { personality, values } = data

    if (!personality || !values) return null

    return (
      <>
        <div className="masonry-item  w-100">
          <h2>Personality</h2>
        </div>

        <div className="masonry-item col-12 col-md-6">
          <Traits
            traits={[
              ...personality,
              ...personality.map(({ children }) => children).flat(1)
            ]}
          />
        </div>
        <div className="masonry-item col-12 col-md-6">
          <Traits traits={values} />
        </div>
      </>
    );
  }

  return (
    <div>
      <MenuDrawer />

      <div className="page-container">
        <TopBar />

        <main className='main-content bgc-grey-100'>
          <div className="filters-bar">
            <ul className="nav-left">
              <li>
                <h1 className="top-title">
                  <GoBack>
                    <i className="ti-arrow-left" style={{ cursor: 'pointer' }} />
                  </GoBack>
                  Search
                </h1>
              </li>
            </ul>
            <form className="form-filters nav-right">
              <div className="form-group d-inline ml-20">
                <label className="d-inline mr-10" htmlFor="exampleFormControlSelect1">Date range:</label>
                <select
                  id="exampleFormControlSelect1"
                  className="form-control d-inline w-auto"
                  onChange={setDateRangeFilter}
                  value={dateRangeFilter}
                >
                  <option value={1000 * 60 * 60 * 24 * 3}>Last 3 days</option>
                  <option value={1000 * 60 * 60 * 24 * 7}>Last week</option>
                  <option value={1000 * 60 * 60 * 24 * 14}>Last 2 week</option>
                  <option value={1000 * 60 * 60 * 24 * 30}>Last month</option>
                  <option value={1000 * 60 * 60 * 24 * 90}>Last 3 months</option>
                  <option value={1000 * 60 * 60 * 24 * 180}>Last 6 months</option>
                  <option value={1000 * 60 * 60 * 24 * 365}>Last year</option>
                </select>
              </div>
            </form>
          </div>
          <div id='mainContent'>
            <div className="row gap-20 masonry pos-r">
              <InfluencerSummary />

              <div className="masonry-item  w-100">
                <h2>Audience reach</h2>
              </div>

              <InfluencerAudienceReach />

              <div className="masonry-item  w-100">
                <h2>Audience engagement</h2>
              </div>

              <InfluencerAudienceEngagement />

              <div className="masonry-item  w-100">
                <h2>Influencer Activity</h2>
              </div>

              <InfluencerActivity />

              {renderAudienceDemographics()}

              {renderAudienceOpinions()}

              {renderInfluencerPersonality()}

              <InfluencerBehaviour />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

import React from 'react';
import { connect } from 'react-redux';

import {
  getInfluencerAudienceMembersByLanguage,
  getYoutubeAudienceGenderDistribution,
  getTwitchAudienceGenderDistribution,
  getInfluencerAudienceTweets,
} from '../../selectors';
import GenderDistribution from '../../../../components/GenderDistribution';
import Languages from '../../../../components/Languages';
import AgeGroups from '../../../../components/AgeGroups';
import between from '../../../../utils/between';

const mapStateToProps = (state, { network }) => {
  const genderDistribution = network === 'twitch'
    ? getTwitchAudienceGenderDistribution(state)
    : getYoutubeAudienceGenderDistribution(state)

  return {
    genderDistribution,
    audienceMembersByLanguage: getInfluencerAudienceMembersByLanguage(state),
    audienceTweets: getInfluencerAudienceTweets(state),
  }
}

const ehance = connect(mapStateToProps);

const InfluencerAudienceDemographics = props => {
  const {
    audienceMembersByLanguage,
    audienceTweets,
    genderDistribution,
  } = props;

  function renderAudienceTopLanguages() {
    const audienceMembersByLanguageData = Object.keys(audienceMembersByLanguage)
      .map(
        language => ({ language, members: audienceMembersByLanguage[language] })
      )
      .sort(
        (languageDataOne, languageDataTwo) => languageDataTwo.members - languageDataOne.members
      )
      .splice(0, 7);

    return (
      <div className="bd bgc-white p-20">
        <div className="layers">
          <div className="layer w-100 mB-10">
            <h6 className="lh-1">TOP LANGUAGES</h6>
          </div>

          <div className="layer w-100 text-center">
            <Languages data={audienceMembersByLanguageData} />
          </div>
        </div>
      </div>
    );
  }

  const genderDistributionExists = genderDistribution && genderDistribution.length > 0

  const topLanguagesExists = Object.keys(audienceMembersByLanguage).length > 0

  const tweetsExist = audienceTweets && audienceTweets.length > 0

  const renderAudienceAgeGroups = () => {
    if (!tweetsExist) return null

    const audienceMembersByAgeGroup = audienceTweets.reduce(
      (reduction, tweet) => {
        const { age } = tweet

        if (!age) return reduction

        let ageGroup

        if (between(12, 17)(age)) {
          ageGroup = '12-18'
        } else if (between(18, 24)(age)) {
          ageGroup = '18-24'
        } else if (between(25, 34)(age)) {
          ageGroup = '25-34'
        } else if (between(35, 44)(age)) {
          ageGroup = '35-44'
        } else if (between(45, 54)(age)) {
          ageGroup = '45-54'
        } else if (between(55, 64)(age)) {
          ageGroup = '55-64'
        } else if (age > 64) {
          ageGroup = '65+'
        }

        if (!ageGroup) return reduction

        return {
          ...reduction,
          [ageGroup]: reduction[ageGroup] + 1,
        }
      },
      {
        '12-17': 0,
        '18-24': 0,
        '25-34': 0,
        '35-44': 0,
        '45-54': 0,
        '55-64': 0,
        '65+': 0,
      }
    )

    return (
      <div className="masonry-item col-6 pT-15 pB-15">
        <div className="bd bgc-white p-20">
          <div className="layers">
            <div className="layer w-100 mB-10">
              <h6 className="lh-1">
                AGE GROUPS
              </h6>
            </div>

            <div className="layer w-100 text-center">
              <AgeGroups data={audienceMembersByAgeGroup} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (
    !genderDistributionExists
    && !topLanguagesExists
    && !tweetsExist
  ) return null

  return (
    <>
      <div className="masonry-item w-100">
        <h2>Audience demographics</h2>
      </div>
      <div className="masonry-item row w-100 col-12 pT-15 pB-15">
        {
          topLanguagesExists && (
            <div className="masonry-item col-6 pT-15 pB-15">
              { renderAudienceTopLanguages() }
            </div>
          )
        }
        {
          genderDistributionExists && (
            <div className="masonry-item col-6 pT-15 pB-15">
              <div className="bd bgc-white p-20">
                <div className="layers">
                  <div className="layer w-100 mB-10">
                    <h6 className="lh-1">
                      GENDER DISTRIBUTION
                    </h6>
                  </div>

                  <div className="layer w-100 text-center">
                    <GenderDistribution data={genderDistribution} />
                  </div>
                </div>
              </div>
            </div>
          )
        }
        { renderAudienceAgeGroups() }
      </div>
    </>
  );
};

export default ehance(InfluencerAudienceDemographics);

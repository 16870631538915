import {
  put, all, delay, call, take, race,
} from 'redux-saga/effects';
import { actions, types } from '../../../store/actions';
import { createAlertsChannel } from './index';

export function* watchDialog() {
  const channel = yield call(createAlertsChannel, null);
  while (true) {
    const payload = yield take(channel);
    yield all([
      call(DialogFlow, payload),
      delay(500),
    ]);
  }
}

function* DialogFlow(payload) {
  try {
    yield put(actions.dialogShow(payload.caption, payload.message, payload.buttons));
    const [isCancelled, result] = yield race([
      take(types.DIALOG_CANCEL),
      take(types.DIALOG_ACCEPT),
    ]);
    payload.resolve(isCancelled ? false : result.payload);
  } catch (ex) {
    payload.resolve(false);
  }
}

export default function* root() {
  yield all([
    watchDialog(),
  ]);
}

import React from 'react';
import { connect } from 'react-redux';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import selectors from '../../../../store/selectors';

const mapStateToProps = state => ({ topCountries: selectors.getTopCountries(state) });

const enhance = connect(mapStateToProps);

const TopCountriesChart = ({ topCountries }) => {
  const displayedTopCountries = topCountries.slice(0, 5);

  return (
    <div style={{ height: 240 }}>
      <ResponsiveContainer>
        <BarChart data={displayedTopCountries}>
          <XAxis
            dataKey="name"
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            dataKey="value"
            axisLine={false}
            tickLine={false}
          />
          <Tooltip />
          <Bar
            barSize={20}
            dataKey="value"
            fill="#009fff"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default enhance(TopCountriesChart);

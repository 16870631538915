import { createReducer, createActions } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const STORAGE_PREFIX = 'influencersPage';

const INITIAL_STATE = {
  influencers : []
};

const { Types, Creators } = createActions({
    requestInfluencerData: ['influencerSearchParams'],
    influencerDataReceived: ['influencerData']
}, {
  prefix: `${STORAGE_PREFIX  }/`,
});

export const requestInfluencerData = (state, {influencerSearchParams}) => {
  console.log("FROM REDUX: requestInfluencerData "+JSON.stringify(influencerSearchParams));
  return state;
};

export const influencerDataReceived = (state, {influencerData}) => {
  console.log("FROM REDUX: influencerDataReceived "+JSON.stringify(influencerData.result));
  return {...state,
    influencers: transformInfluencersData(influencerData.result),
  };
};

function transformInfluencersData(influencersData) {
  return influencersData.map(influencer => (
    {
      ...influencer,
      picURL: influencer.profile_image_url,
      influencerName: influencer.display_name,
      influencerType: influencerSize(influencer),
      influencerMatchingScore: influencer.match_score && Math.round(influencer.match_score),
      influencerGrowthScore: influencer.follower_virality && Math.round(influencer.follower_virality),
      login: influencer.login,
      network: influencer.network,
    }
  ));
}

function influencerSize(influencer) {
  if (influencer.size)
    return influencer.size;

    if (influencer.subscriber_count >= 1000000)
      return 'MEGA INFLUENCER';
    if (influencer.subscriber_count < 1000000 && influencer.subscriber_count >= 100000)
      return 'MACRO INFLUENCER';
    if (influencer.subscriber_count < 100000 && influencer.subscriber_count >= 10000)
      return 'MICRO INFLUENCER';
    if (influencer.subscriber_count < 100000 && influencer.subscriber_count >= 10000)
      return 'MICRO INFLUENCER';
    else
      return 'NANO INFLUENCER';
}

export const HANDLERS = {
  [Types.REQUEST_INFLUENCER_DATA]: requestInfluencerData,
  [Types.INFLUENCER_DATA_RECEIVED]: influencerDataReceived
};

const persistConfig = {
  key: STORAGE_PREFIX,
  storage,

  whitelist: [],
};

export const reducer = persistReducer(persistConfig, createReducer(INITIAL_STATE, HANDLERS));
export const actionTypes = Types;
export default Creators;

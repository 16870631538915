import React, { useState, useEffect } from 'react'
import WordCloud from 'react-d3-cloud'
import { Doughnut } from 'react-chartjs-2'

import sparklineInit from '../../../../charts/sparkline'
import SentimentMeter from '../../../../charts/SentimentMeter'
import { MenuDrawer, TopBar } from '../../../../common/components'

import sum from '../../../../utils/sum'
import percent from '../../../../utils/percent'
import TopCountriesChart from './TopCountriesChart'
import TopLanguagesChart from './TopLanguagesChart'
import GamingCommunitiesMentionsChart from './GamingCommunitiesMentionsChart'
import MentionsTimelineChart from './MentionsTimelineChart'
import BrandPageHeader from './BrandPageHeader'
import BrandAudienceAgeDistribution from './BrandAudienceAgeDistribution'
import Comments from '../../../../components/Comments';

const fontSizeMapper = word => Math.log2(word.value) * 5;

export const BrandPage = ({
  mentions,
  wordcloudData,
  comments,
  sentimentMeterData,
  positiveMentionsCount,
  slightlyPositiveMentionsCount,
  slightlyNegativeMentionsCount,
  negativeMentionsCount,
  genderDistributionData,
  onLoadToCall,
}) => {
  const initialized = useState(false);

  useEffect(
    () => {
      if (initialized) {
        onLoadToCall();
      }

      sparklineInit();
    },
    []
  );

  return (
    <div>
      <MenuDrawer />
      <div className="page-container">
        <TopBar />
        <div>
          <main className="main-content bgc-grey-100">
            <div id="mainContent">
              <BrandPageHeader />
              <div className="row gap-20 masonry pos-r">
                <div className="masonry-sizer col-md-6" />
                <div className="masonry-item  w-100">
                  <h2>Mentions</h2>
                </div>
                <div className="masonry-item col-12">
                  {/* #Site Visits ==================== */}
                  <div>
                  </div>
                  <div className="bd bgc-white">
                    <div>
                      <div className="row">
                        <div className="col-xs-12 col-md-4 border-right">
                          <div className="pX-20 pT-20 w-100 mB-10">
                            <h6 className="lh-1">NUMBER OF MENTIONS <i className="fa-info-circle" data-toggle="tooltip" data-placement="top" data-original-title="Tooltip on top" /></h6>
                          </div>
                          <div className="number-chart mT-50">
                            <p className="number-chart-digit">{mentions.noOfMentions}</p>
                            <p className="number-chart-text">mentions</p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-md-8">
                          <div className="pX-20 pT-20 w-100 mB-10">
                            <h6 className="lh-1">MENTIONS TIMELINE <i className="fa-info-circle" data-toggle="tooltip" data-placement="top" data-original-title="Tooltip on top" /></h6>
                          </div>
                          <div>
                            <MentionsTimelineChart />
                          </div>
                        </div>
                      </div>

                      { /*
                  <div className="peer bdL p-20 w-70p@lg+ w-100p@lg-">
                    <div className="layers">
                      <div className="layer w-100">
                        <h6 className="lh-1">MENTIONS TIMELINE <i className="fa-info-circle" data-toggle="tooltip" data-placement="top" data-original-title="Tooltip on top" /></h6>
                        <Line data={mentions.mentionsTimeline} />
                      </div>
                    </div>
                  </div>
                  */}
                    </div>
                  </div>
                </div>

                <div className="masonry-item col-md-12">

                  <div className="bd bgc-white">
                    <div className="layers">
                      <div className="layer w-100 pX-20 pT-20">
                        <h6 className="lh-1">GAMING COMMUNITIES MENTIONS <i className="fa-info-circle" data-toggle="tooltip" data-placement="top" data-original-title="Tooltip on top" /></h6>
                      </div>
                      <div className="layer w-100 p-20">
                        <div className="layers">
                          <GamingCommunitiesMentionsChart />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="masonry-item  w-100">
                  <h2>Audience reaction</h2>
                </div>
                <div className="masonry-item col-12">
                  <div className="bd bgc-white">
                    <div className="peers fxw-nw@lg+ ai-s">
                      <div className="peer peer-greed w-30p@lg+ w-100@lg- p-20">
                        <div className="layers">
                          <div className="layer w-100 mB-10">
                            <h6 className="lh-1">OVERALL SENTIMENT</h6>
                          </div>
                          <div className="layer w-100 text-center">
                            <SentimentMeter value={sentimentMeterData} />
                          </div>
                          <ul className="p-20 nav flex-column w-100">
                            <li className="nav-item">
                              <a href="javascript:void(0)" className="nav-link c-grey-800 cH-blue-500 active">
                                <div className="peers ai-c jc-sb">
                                  <div className="peer peer-greed">
                                    <span className="sentiment-list-dot sentiment-p" />
                                    <span className="list-item-left">Positive mentions</span>
                                  </div>
                                  <div className="peer">
                                    <i className="ti-comment-alt" />
                                    <span className="list-item-right">{positiveMentionsCount}</span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link c-grey-800 cH-blue-500">
                                <div className="peers ai-c jc-sb">
                                  <div className="peer peer-greed">
                                    <span className="sentiment-list-dot sentiment-sp" />
                                    <span className="list-item-left">Slightly positive mentions</span>
                                  </div>
                                  <div className="peer">
                                    <i className="ti-comment-alt" />
                                    <span className="list-item-right">{slightlyPositiveMentionsCount}</span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link c-grey-800 cH-blue-500">
                                <div className="peers ai-c jc-sb">
                                  <div className="peer peer-greed">
                                    <span className="sentiment-list-dot sentiment-sn" />
                                    <span className="list-item-left">Slightly negative mentions</span>
                                  </div>
                                  <div className="peer">
                                    <i className="ti-comment-alt" />
                                    <span className="list-item-right"> {slightlyNegativeMentionsCount}</span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link c-grey-800 cH-blue-500">
                                <div className="peers ai-c jc-sb">
                                  <div className="peer peer-greed">
                                    <span className="sentiment-list-dot sentiment-n" />
                                    <span className="list-item-left">Negative mentions</span>
                                  </div>
                                  <div className="peer">
                                    <i className="ti-comment-alt" />
                                    <span className="list-item-right"> {negativeMentionsCount}</span>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      { /*
                  <div className="peer bdL p-20 w-70p@lg+ w-100p@lg-">
                    <div className="layers">
                      <div className="layer w-100">
                        <h6 className="lh-1">OVERALL SENTIMENT OVER TIME</h6>
                      </div>
                      <div className="layer w-100 text-center">
                      <SentimentChart data = {sentimentChartData}/>
                      </div>
                    </div>
                  </div>
                  */
                      }

                    </div>
                  </div>
                </div>
                <div className="masonry-item  w-100">
                  <h2>Audience opinions</h2>
                </div>
                <div className="masonry-item col-12">

                  <div className="bd bgc-white">
                    <div className="peers fxw-nw@lg+ ai-s">
                      <div className="row w-100">
                        <div className="col-12 col-md-6 border-right">
                          <Comments comments={comments} />
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="peer p-20">
                            <div className="layers">
                              <div className="layer w-100">
                                <h6 className="lh-1">TALKING ABOUT</h6>
                              </div>
                              <div className="layer w-100 text-center">
                                <WordCloud
                                  data={wordcloudData}
                                  fontSizeMapper={fontSizeMapper}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="masonry-item  w-100">
                  <h2>Audience demographics</h2>
                </div>
                <div className="masonry-item col-md-6">
                  <div className="bd bgc-white p-20">
                    <div className="layers">
                      <div className="layer w-100 mB-10">
                        <h6 className="lh-1">AGE GROUP</h6>
                      </div>
                      <div className="layer w-100 text-center">
                        <BrandAudienceAgeDistribution />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="masonry-item col-md-6">
                  <div className="bd bgc-white p-20">
                    <div className="layers">
                      <div className="layer w-100 mB-10">
                        <h6 className="lh-1">GENDER DISTRIBUTION</h6>
                      </div>
                      <div className="layer w-100 text-center">
                        <Doughnut
                          data={genderDistributionData}
                          options={{
                            tooltips: {
                              callbacks: {
                                label: ({ index }, { labels, datasets }) => {
                                  const total = sum(datasets[0].data);

                                  return `${labels[index]}: ${percent(total, datasets[0].data[index])}%`;
                                },
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="masonry-item col-md-6">
                  <div className="bd bgc-white p-20">
                    <div className="layers">
                      <div className="layer w-100 mB-10">
                        <h6 className="lh-1">TOP COUNTRIES</h6>
                      </div>
                      <div className="layer w-100 text-center">
                        <TopCountriesChart />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="masonry-item col-md-6">
                  <div className="bd bgc-white p-20">
                    <div className="layers">
                      <div className="layer w-100 mB-10">
                        <h6 className="lh-1">TOP LANGUAGES</h6>
                      </div>
                      <div className="layer w-100 text-center">
                        <TopLanguagesChart />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import qs from 'query-string';
import classNames from 'classnames';

import { MenuDrawer, TopBar } from '../../../../common/components';
import * as paths from '../../../../common/constants/paths';
import { types } from '../../../../store/actions';
import clamp from '../../../../utils/clamp'
import Loader from '../../../../components/Loader';

const MATCHING_SCORE_ASCENDING = 'MATCHING_SCORE_ASCENDING'
const MATCHING_SCORE_DESCENDING = 'MATCHING_SCORE_DESCENDING'
const GROWTH_SCORE_ASCENDING = 'GROWTH_SCORE_ASCENDING'
const GROWTH_SCORE_DESCENDING = 'GROWTH_SCORE_DESCENDING'
const ENGAGEMENT_RATE_ASCENDING = 'ENGAGEMENT_RATE_ASCENDING'
const ENGAGEMENT_RATE_DESCENDING = 'ENGAGEMENT_RATE_DESCENDING'

const Influencers = props => {
  const { dispatch, location } = props;

  const [sorter, setSorter] = useState(undefined)

  useEffect(
    () => {
      const requestPayload = qs.parse(location.search)

      dispatch({
        type: types.REQUEST_INFLUENCER_DATA,
        influencerSearchParams: requestPayload,
      });
    },
    [location.search]
  )

  let { influencers } = props

  if (sorter) {
    influencers.sort(
      (
        {
          influencerMatchingScore: matchingScoreA,
          influencerGrowthScore: growthScoreA,
          engagement_avg: engagementRateA,
        },
        {
          influencerMatchingScore: matchingScoreB,
          influencerGrowthScore: growthScoreB,
          engagement_avg: engagementRateB,
        },
      ) => {
        switch (sorter) {
          case MATCHING_SCORE_ASCENDING: {
            return matchingScoreB - matchingScoreA
          }

          case MATCHING_SCORE_DESCENDING: {
            return matchingScoreA - matchingScoreB
          }

          case GROWTH_SCORE_ASCENDING: {
            return growthScoreB - growthScoreA
          }

          case GROWTH_SCORE_DESCENDING: {
            return growthScoreA - growthScoreB
          }

          case ENGAGEMENT_RATE_ASCENDING: {
            return engagementRateB - engagementRateA
          }

          case ENGAGEMENT_RATE_DESCENDING: {
            return engagementRateA - engagementRateB
          }

          default: return 0
        }
      }
    )
  }

  const { influencer_network } = qs.parse(location.search)

  const renderSortByGrowthScore = () => {
    /*
     * there is not growth-score for youtube
     */
    if (influencer_network === 'youtube') return null

    return (
      <div className="option-content">
        <p className="option-title">Growth score</p>
        <ul className="option-list option-sort">
          <li>
            <button
              onClick={() => setSorter(GROWTH_SCORE_ASCENDING)}
              className={
                classNames(
                  'option-selector',
                  {
                    'option-selected': sorter === GROWTH_SCORE_ASCENDING
                  },
                )
              }
            >
              <i className="ia fa-sort-amount-desc" />
            </button>
          </li>
          <li>
            <button
              onClick={() => setSorter(GROWTH_SCORE_DESCENDING)}
              className={
                classNames(
                  'option-selector',
                  {
                    'option-selected': sorter === GROWTH_SCORE_DESCENDING
                  },
                )
              }
            >
              <i className="ia fa-sort-amount-asc" />
            </button>
          </li>
        </ul>
      </div>
    )
  }

  const renderSortByEngagementRate = () => {
    /*
     * there is not engagement-rate for youtube
     */
    if (influencer_network === 'youtube') return null

    return (
      <div className="option-content">
        <p className="option-title">Engagement rate</p>
        <ul className="option-list option-sort">
          <li>
            <button
              onClick={() => setSorter(ENGAGEMENT_RATE_ASCENDING)}
              className={
                classNames(
                  'option-selector',
                  {
                    'option-selected': sorter === ENGAGEMENT_RATE_ASCENDING
                  },
                )
              }
            >
              <i className="ia fa-sort-amount-desc" />
            </button>
          </li>
          <li>
            <button
              onClick={() => setSorter(ENGAGEMENT_RATE_DESCENDING)}
              className={
                classNames(
                  'option-selector',
                  {
                    'option-selected': sorter === ENGAGEMENT_RATE_DESCENDING
                  },
                )
              }
            >
              <i className="ia fa-sort-amount-asc" />
            </button>
          </li>
        </ul>
      </div>
    )
  }

  const renderMainContent = () => {
    if (influencers.length === 0) return null

    return (
      <div id='mainContent'>
        <div className="row gap-20 masonry pos-r">
          <div className="masonry-sizer col-md-6"></div>
          <div className="masonry-item  w-100">
            <h2>Influencers matching your search</h2>
          </div>
          <div className="masonry-item w-100 influencers-list">
            <div className="row gap-20">

              {
                influencers.map(
                  influencer => (
                    <div key={influencer.login} className="col-md-3">
                      <Link
                        className="influencer-box-link"
                        to={`${paths.INFLUENCER}/${influencer.login}/${influencer.network}`}
                      >
                        <div className="layers bd bgc-white p-20">
                          <div className="layer w-100">
                            <div className="tile-item text-center">
                              <div className="influencer-avatar-holder">
                                <img src={influencer.picURL} />
                              </div>
                              <div className="influencer-bio">
                                <p className="influencer-name">{influencer.influencerName}</p>
                                <p className="influencer-type">{influencer.influencerType}</p>
                              </div>
                              <div className="row mt-10" hidden={!influencer.influencerMatchingScore}>
                                <div className="col-lg-6 text-center">
                                  <div className="score-container">
                                    <p className="influencer-score">
                                      {clamp(0, 100)(influencer.influencerMatchingScore)}
                                      %
                                        </p>
                                    <p className="influencer-score-text">Matching Score</p>
                                  </div>
                                </div>
                                <div className="col-lg-6 text-center" hidden={!influencer.influencerGrowthScore}>
                                  <div className="score-container">
                                    <p className="influencer-score secondary-color">
                                      {clamp(0, 100)(influencer.influencerGrowthScore)}
                                      %
                                        </p>
                                    <p className="influencer-score-text">Growth Score</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <MenuDrawer />
      <div className="page-container">
        <TopBar />

        <main className='main-content bgc-grey-100'>
          <div className="filters-bar">
            <ul className="nav-left">
              <li>
                <h1 className="top-title">Search for influencers</h1>
              </li>
            </ul>
            <ul className="nav-right">
              <li>
                <button
                  type="button"
                  className="btn cur-p btn-outline-primary"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="ia fa-sliders" />
                  SEARCH OPTIONS
                </button>
                <Link to={paths.INFLUENCER_SEARCH} className="btn cur-p btn-primary"><i className="ia fa-search"></i>REFINE YOUR SEARCH</Link>
              </li>
            </ul>
          </div>
          <div
            className="collapse search-option-container"
            id="collapseExample"
          >
            <div className="card card-body">
              {/* <div className="filtering-options"> */}
              {/* <div className="option-section">
                  <p>FILTERING OPTIONS</p>
                </div> */}

              {/* <div className="option-content">
                  <p className="option-title">Influencer size</p>
                  <ul className="option-list">
                    <li><a className="option-selector option-selected" href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="1k - 10k followers">Nano</a></li>
                    <li><a className="option-selector" href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="10k - 100k followers">Micro</a></li>
                    <li><a className="option-selector" href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="100k - 1M followers">Macro</a></li>
                    <li><a className="option-selector" href="" data-toggle="tooltip" data-placement="top" title="" data-original-title="1M+ followers">Hero</a></li>
                  </ul>
                </div> */}

              {/* <div className="option-content">
                  <p className="option-title">Personality traits</p>
                  <ul className="option-list">
                    <li><a className="option-selector" href="">Openess</a></li>
                    <li><a className="option-selector" href="">Conscientiousness</a></li>
                    <li><a className="option-selector" href="">Extraversion</a></li>
                    <li><a className="option-selector option-selected" href="">Agreeableness</a></li>
                    <li><a className="option-selector" href="">Neuroticism</a></li>
                  </ul>
                </div> */}

              {/* <div className="option-content">
                  <p className="option-title">Content type</p>
                  <ul className="option-list">
                    <li><a className="option-selector" href="">Adult</a></li>
                    <li><a className="option-selector" href="">Non-adult</a></li>
                  </ul>
                </div> */}
              {/* </div> */}
              <div className="filtering-options">
                <div className="option-section">
                  <p>SORTING OPTIONS</p>
                </div>
                <div className="option-content">
                  <p className="option-title">Matching score</p>
                  <ul className="option-list option-sort">
                    <li>
                      <button
                        onClick={() => setSorter(MATCHING_SCORE_ASCENDING)}
                        className={
                          classNames(
                            'option-selector',
                            {
                              'option-selected': sorter === MATCHING_SCORE_ASCENDING
                            },
                          )
                        }
                      >
                        <i className="ia fa-sort-amount-desc" />
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={() => setSorter(MATCHING_SCORE_DESCENDING)}
                        className={
                          classNames(
                            'option-selector',
                            {
                              'option-selected': sorter === MATCHING_SCORE_DESCENDING
                            },
                          )
                        }
                      >
                        <i className="ia fa-sort-amount-asc" />
                      </button>
                    </li>
                  </ul>
                </div>

                {renderSortByGrowthScore()}


                {/* <div className="option-content">
                  <p className="option-title">Number of follwers</p>
                  <ul className="option-list option-sort">
                  <li><a className="option-selector" href=""><i className="ia fa-sort-amount-desc"></i></a></li>
                  <li><a className="option-selector" href=""><i className="ia fa-sort-amount-asc"></i></a></li>
                  </ul>
                </div> */}

                {renderSortByEngagementRate()}

                {/* <div className="option-content">
                  <p className="option-title">Estimated media value</p>
                  <ul className="option-list option-sort">
                    <li><a className="option-selector" href=""><i className="ia fa-sort-amount-desc"></i></a></li>
                    <li><a className="option-selector" href=""><i className="ia fa-sort-amount-asc"></i></a></li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>

          {influencers.length === 0 && <Loader />}

          {renderMainContent()}

        </main>
      </div>
    </div>
  )
}

export default Influencers

import * as app from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';

// PUT IN YOUR CONFIG HERE, AND SHARE ACCESS TO YOUR PROJECT
const config = {
  apiKey: 'AIzaSyCH6KQHxbmqSTvSnzXvkjDbxvvwxJk0xa8',
  authDomain: 'aggero-ccdae.firebaseapp.com',
  databaseURL: 'https://aggero-ccdae.firebaseio.com',
  projectId: 'aggero-ccdae',
  storageBucket: 'aggero-ccdae.appspot.com',
  messagingSenderId: '749293303051',
};

/*
const config = {
  apiKey: 'AIzaSyAiM9d_uqWrN0IG_U-asBMGLygqHV9xJuM',
  authDomain: 'anpakoproject.firebaseapp.com',
  databaseURL: 'https://anpakoproject.firebaseio.com',
  projectId: 'anpakoproject',
  storageBucket: 'anpakoproject.appspot.com',
  messagingSenderId: '59744416319',
};
*/

class FirebaseClass {
  constructor() {
    this.app = app.initializeApp(config);
    this.db = app.firestore();
    this.auth = this.app.auth();
    this.functions = this.app.functions();
    this.database = this.app.database();
    this.isLoaded = false;
  }

  getBrandPageData = async userEmail => {
    const userCompaniesResponse = await app.firestore()
      .collection('user_companies')
      .doc(userEmail)
      .get();

    const userCompanies = await userCompaniesResponse.data();

    const querySnapshot = await app.firestore()
      .collection('companyData')
      .where('companyName', '==', userCompanies.company.toLowerCase())
      .get()
      .then(
        querySnapshot => {
          return querySnapshot.docs[0].data();
        }
      );
    return querySnapshot;
  }

  getBrandTweets = async userEmail => {
    const userCompaniesResponse = await app.firestore()
      .collection('user_companies')
      .doc(userEmail)
      .get();

    const userCompanies = await userCompaniesResponse.data();

    const querySnapshot = await app.firestore()
      .collection('company_tweets')
      .doc(userCompanies.company)
      .get()
      .then(
        querySnapshot => {
          return querySnapshot.data()['tweets'];
        }
      );
    return querySnapshot;
  }

  getInfluencers = async (influencerSearchParams) => {
    const querySnapshot = await app.firestore()
      .collection('influencers')
      .get()
      .then(snapshot => snapshot.docs.map(doc => doc.data()));

    return querySnapshot;
  }

  getInfluencerById = influencerId => {
    return app.firestore()
      .collection('influencers')
      .doc(influencerId)
      .get()
      .then(querySnapshot => querySnapshot.data());
  }

  getYoutubeDataByInfluencerId = influencerId => {
    return app.firestore()
      .collection('youtube_data')
      .doc(decodeURI(influencerId))
      .get()
      .then(querySnapshot => querySnapshot.data());
  }

  getYoutubeCommentsByInfluencerId = influencerId => {
    return app.firestore()
      .collection('youtube_comments')
      .doc(decodeURI(influencerId))
      .get()
      .then(querySnapshot => querySnapshot.data());
  }

  getTwitchChatByInfluencerId = influencerId => {
    return app.firestore()
      .collection('twitch_chat')
      .doc(decodeURI(influencerId))
      .get()
      .then(querySnapshot => querySnapshot.data());
  }

  getInfluencerMentionTweetsByInfluencerId = async influencerId => {
    const snapshot = await app.firestore()
      .collection('influencer_mention_tweets')
      .where('influencer_id', '==', influencerId)
      .get()

    return snapshot.docs.map(doc => doc.data())
  }

  getInfluencerDetails = async influencerId => {
    const [
      influencer,
      youtube,
      twitch,
    ] = await Promise.all(
      [
        this.getInfluencerById(influencerId),
        this.getYoutubeDataByInfluencerId(influencerId),
        this.getTwitchChatByInfluencerId(influencerId),
      ]
    )

    return { influencer, youtube, twitch };
  }

  getCurrentUserId = () => {
    return app.auth().currentUser;
  }
  // New user registration with Email + Password

  createUserWithCredentials = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  // Authentication

  signInWithCredentials = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  // Authentication Google

  signInWithGoogle = () => {
    const provider = new app.auth.GoogleAuthProvider();
    // provider.addScope('https://www.googleapis.com/auth/gmail.compose');
    return this.auth.signInWithPopup(provider);
  }

  // Authentication Facebook

  signInWithFacebook = () => {
    const provider = new app.auth.FacebookAuthProvider();
    // provider.addScope('email');
    // provider.addScope('ads_management');
    return this.auth.signInWithPopup(provider);
  }

  // User sign out

  signout = () => this.auth.signOut();

  // Password reset

  passwordReset = email => this.auth.sendPasswordResetEmail(email);

  // Create new user record

  addUser = (userId, displayName, email, photoURL) => {
    const cloudFunc = this.functions.httpsCallable('addUser');
    return cloudFunc({
      userId, displayName, email, photoURL,
    });
  }

  // Add new connection

  addConnection = (connType, code) => {
    const cloudFunc = this.functions.httpsCallable('addConnection');
    return cloudFunc({ connType, code });
  }

  // Remove connection

  removeConnection = (connType, id) => {
    const cloudFunc = this.functions.httpsCallable('removeConnection');
    return cloudFunc({
      connType, id,
    });
  };

  // get Google auth link

  getGoogleAuthLink = () => {
    const cloudFunc = this.functions.httpsCallable('getConnectionLinkGoogle');
    return cloudFunc();
  };

  // get user picture

  getUserProfiles = (payload) => {
    const cloudFunc = this.functions.httpsCallable('getUserProfiles');
    return cloudFunc({ payload });
  };

  // add template

  addTemplate = (payload) => {
    const cloudFunc = this.functions.httpsCallable('addTemplate');
    return cloudFunc({ payload });
  };

  // remove template

  removeTemplate = id => {
    const cloudFunc = this.functions.httpsCallable('removeTemplate');
    return cloudFunc({ id });
  };

  // BRAND PAGE

  getBrandData = (companyId, filters) => {
    var db = this.app.firestore();
  };
}

const Firebase = new FirebaseClass();

export default Firebase;

import React from 'react';
import { connect } from 'react-redux';

import selectors from '../../../../store/selectors';
import actions from '../../../../store/actions';

const mapStateToProps = state => ({
  companyName: selectors.getCompanyName(state),
});

const mapDispatchToProps = { mentionFilterChanged: actions.mentionFilterChanged };

const BrandPageHeader = ({
  companyName,
  mentionFilterChanged,
}) => {
  const handleFilterChange = ({ target: { value, name } }) => {
    mentionFilterChanged({
      value,
      name,
    });
  };

  return (
    <div className="filters-bar">
      <ul className="nav-left">
        <li>
          <h1 className="top-title">{companyName}</h1>
        </li>
      </ul>
      <ul className="nav-right">
        <li>
          <form className="form-filters">
            <div className="form-group d-inline mr-20">
              <label className="d-inline mr-10" htmlFor="exampleFormControlSelect1">Date range:</label>
              <select
                onChange={handleFilterChange}
                name="mentionsTimeAgoFilter"
                className="form-control d-inline w-auto"
              >
                <option value="">Anytime</option>
                <option value={1000 * 60 * 60 * 24 * 7}>Last 7 days</option>
                <option value={1000 * 60 * 60 * 24 * 30}>Last month</option>
                <option value={1000 * 60 * 60 * 24 * 90}>Last 3 months</option>
                <option value={1000 * 60 * 60 * 24 * 365}>Last year</option>
                <option value={1000 * 60 * 60 * 24 * 365 * 3}>Last 3 years</option>
                <option value={1000 * 60 * 60 * 24 * 365 * 5}>Last 5 years</option>
              </select>
            </div>
            <div className="form-group d-inline mr-20">
              <label className="d-inline mr-10" htmlFor="exampleFormControlSelect1">Platform:</label>
              <select
                onChange={handleFilterChange}
                name="mentionsPlatformFilter"
                className="form-control d-inline w-auto"
              >
                <option value="">All</option>
                <option value="twitter">Twitter</option>
                <option value="reddit">Reddit</option>
              </select>
            </div>
            {/* <div className="form-group d-inline mr-20">
              <label className="d-inline mr-10" htmlFor="exampleFormControlSelect1">Location:</label>
              <select className="form-control d-inline w-auto" id="exampleFormControlSelect1">
                <option>United States</option>
                <option>United Kingdom</option>
                <option>Romania</option>
              </select>
            </div> */}
          </form>
        </li>
      </ul>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandPageHeader);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Popover.scss';

const Popover = ({
  open,
  children,
  content,
  over,
  under,
  left,
  right,
  align,
  component: Component = 'div',

  onClose,
}) => {
  /*
   * This pair of popover-click event-propagation prevention together with the
   * window click-listener makes sure that 'onClose' is triggered should a click
   * outside of the Popover be detected
   *
   * Basically, if a click-event on window is detected trigger onClose, however if
   * Popover is clicked make sure this event's bubbling is stopped ensuring that the
   * click-event never reaches the window's click-handler
   *
   * TODO: extract into composable component with just the click-outside listener
   * functionality and maybe improve the way this click-outside is detected
   */
  const onPopoverClick = event => {
    event.stopPropagation();
  };
  useEffect(
    () => {
      if (open) {
        window.addEventListener('click', onClose);
      }

      return () => {
        window.removeEventListener('click', onClose);
      };
    },
    [open]
  );

  if (!open) return children;

  const popoverClassName = classNames(
    'popover__root',
    {
      'popover__align-top': align === 'top',
      'popover__align-bottom': align === 'bottom',
      'popover__align-left': align === 'left',
      'popover__align-right': align === 'right',
      'popover__position-over': over,
      'popover__position-under': under,
      'popover__position-left': left,
      'popover__position-right': right,
    }
  );

  return (
    <div className="popover__container">
      <Component
        onClick={onPopoverClick}
        className={popoverClassName}
      >
        {content}
      </Component>

      {children}
    </div>
  );
};

Popover.propTypes = {

  /*
   * If true, the popover is open
   */
  open: PropTypes.bool,

  /*
   * The React.Node this popover should use as its anchor
   */
  children: PropTypes.node.isRequired,

  /*
   * The content of the popover
   */
  content: PropTypes.node.isRequired,

  /*
   * If true, the popover is placed over the anchor
   */
  over: PropTypes.bool,

  /*
   * If true, the popover is placed under the anchor
   */
  under: PropTypes.bool,

  /*
   * If true, the popover is placed to the left of the anchor
   */
  left: PropTypes.bool,

  /*
   * If true, the popover is placed to the right of the anchor
   */
  right: PropTypes.bool,

  /*
   * The alignment of the popover relative to its anchor
   */
  align: PropTypes.oneOf(['top', 'bottom', 'left', 'right', 'middle']),

  /*
   * The component used for the root node
   */
  component: PropTypes.elementType,

  /*
   * Callback fired when Popover detects an interaction that would require it to close
   */
  onClose: PropTypes.func,
};

export default Popover;

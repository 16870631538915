import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { object, string } from 'yup';

import { InputField } from '../../../common/components/InputField/dumb';

const formik = {
  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
    bag.props.close(values);
  },
  validationSchema: object().shape({
    keyword: string().required(),
  }),
  mapPropsToValues: () => ({ keyword: '' }),
};

const enhance = withFormik(formik);

const AddKeywordForm = props => {
  const {
    values,
    errors,
    touched,

    handleChange,
    handleBlur,
    submitForm,
  } = props;

  const handleSubmit = event => {
    /*
     * nested HTML forms are an anti-pattern and their behaviour is not covered
     * in the HTML speficiation, resulting in invalid HTML according to the W3C
     * specification
     *
     * TODO: find a more idiomatic way to prevent a nested form from submitting
     * its parent form / find a way to recreate this behaviour without using a
     * nested form, e.g. differentiating the submission in the parent submission
     * and continuing it based on which input it was triggered from
     */
    event.stopPropagation();

    submitForm();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="card" style={{ minWidth: 400 }}>
        <div className="card-body">
          <InputField
            label="Keyword"
            type="text"
            placeholder="Type a keyword which best describes the content"
            meta={{
              touched: touched.keyword,
              error: errors.keyword,
            }}
            input={{
              name: 'keyword',
              value: values.keyword,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
          />
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={submitForm}
          >
            ADD
        </button>
        </div>
      </div>
    </form>
  );
};

const EnhancedAddKeywordForm = enhance(AddKeywordForm);

EnhancedAddKeywordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default EnhancedAddKeywordForm;

import React, { useEffect, useState, memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';

import mapSelectorsToProps from '../../../../helpers/map-selectors-to-props';
import { getInfluencerBehaviour } from '../../selectors';

const audienceBehaviourTypeByBehaviour = {
  'read often': 'lifestyle',
  'eat out frequently': 'lifestyle',
  'have a gym membership': 'lifestyle',
  'be concerned about the environment': 'lifestyle',
  'consider starting a business in next few years': 'lifestyle',
  'have experience playing music': 'lifestyle',
  'attend live musical events': 'lifestyle',
  'volunteer for social causes': 'lifestyle',
  'like romance movies': 'lifestyle',
  'like science-fiction movies': 'lifestyle',
  'like war movies': 'lifestyle',
  'like adventure movies': 'lifestyle',
  'like historical movies': 'lifestyle',
  'like action movies': 'lifestyle',
  'like documentary movies': 'lifestyle',
  'like outdoor activities': 'lifestyle',
  'like horror movies': 'lifestyle',
  'like musical movies': 'lifestyle',
  'like drama movies': 'lifestyle',
  'like rock music': 'lifestyle',
  'like classical music': 'lifestyle',
  'like rap music': 'lifestyle',
  'like R&B music': 'lifestyle',
  'like hip hop music': 'lifestyle',
  'like Latin music': 'lifestyle',
  'like country music': 'lifestyle',
  'read non-fiction books': 'lifestyle',
  'read autobiographical books': 'lifestyle',
  'read entertainment magazines': 'lifestyle',
  'read financial investment books': 'lifestyle',
  'prefer quality when buying clothes': 'purchasing',
  'prefer comfort when buying clothes': 'purchasing',
  'prefer style when buying clothes': 'purchasing',
  'prefer safety when buying automobiles': 'purchasing',
  'be sensitive to ownership cost when buying automobiles': 'purchasing',
  'be influenced by product utility when making product purchases': 'purchasing',
  'be influenced by online ads when making product purchases': 'purchasing',
  'be influenced by social media when making product purchases': 'purchasing',
  'be influenced by brand name when making product purchases': 'purchasing',
  'be influenced by family when making product purchases': 'purchasing',
  'prefer using credit cards for shopping': 'purchasing',
  'indulge in spur of the moment purchases': 'purchasing',
};

const audienceBehaviourCategoryByBehaviour = {
  'prefer quality when buying clothes': 'preference',
  'prefer comfort when buying clothes': 'preference',
  'prefer style when buying clothes': 'preference',
  'prefer safety when buying automobiles': 'preference',
  'like romance movies': 'preference',
  'like science-fiction movies': 'preference',
  'like war movies': 'preference',
  'like adventure movies': 'preference',
  'like historical movies': 'preference',
  'like action movies': 'preference',
  'like documentary movies': 'preference',
  'like outdoor activities': 'preference',
  'like horror movies': 'preference',
  'like musical movies': 'preference',
  'like drama movies': 'preference',
  'like rock music': 'preference',
  'like classical music': 'preference',
  'like rap music': 'preference',
  'like R&B music': 'preference',
  'like hip hop music': 'preference',
  'like Latin music': 'preference',
  'like country music': 'preference',
  'read non-fiction books': 'preference',
  'read autobiographical books': 'preference',
  'read entertainment magazines': 'preference',
  'read financial investment books': 'preference',
  'to be sensitive to ownership cost when buying automobiles': 'behaviour',
  'be influenced by product utility when making product purchases': 'behaviour',
  'be influenced by online ads when making product purchases': 'behaviour',
  'be influenced by social media when making product purchases': 'behaviour',
  'be influenced by brand name when making product purchases': 'behaviour',
  'be influenced by family when making product purchases': 'behaviour',
  'prefer using credit cards for shopping': 'behaviour',
  'indulge in spur of the moment purchases': 'behaviour',
  'read often': 'behaviour',
  'eat out frequently': 'behaviour',
  'have a gym membership': 'behaviour',
  'be concerned about the environment': 'behaviour',
  'consider starting a business in next few years': 'behaviour',
  'have experience playing music': 'behaviour',
  'attend live musical events': 'behaviour',
  'volunteer for social causes': 'behaviour',
}

const mapStateToProps = mapSelectorsToProps({ behaviour: getInfluencerBehaviour })

const enhance = compose(
  memo,
  connect(mapStateToProps)
);

const Behaviour = ({ behaviours, label }) => {
  const [pagination, setPagination] = useState(0);

  const [category, setCategory] = useState('lifestyle');

  function handleCategorySelected({ target: { value } }) {
    setCategory(value)
  }

  const [likeliness, setLikeliness] = useState('Most likely to');

  function handleLikelinessSelected({ target: { value } }) {
    setLikeliness(value)
  }

  useEffect(
    () => { setPagination(0) },
    [ category, likeliness ]
  )

  const filteredBehaviours = behaviours
    .filter(
      ({ name }) => audienceBehaviourTypeByBehaviour[name] === category
    )
    .filter(
      ({ likeliness: l }) => l === likeliness
    )

  const sortedBehaviours = filteredBehaviours.sort(
    (behaviourA, behaviourB) => behaviourB.score - behaviourA.score
  )

  const visibleBehaviours = sortedBehaviours.slice(
    pagination * 4,
    (pagination + 1) * 4
  );

  const buttons = []

  for (let i = 0; i < filteredBehaviours.length / 4; i++) {
    const onClick = () => { setPagination(i); };

    const className = classNames(
      'btn',
      'btn-light',
      {
        active: pagination === i,
      }
    );

    buttons.push(
      <button
        key={behaviours[i * 4].name}
        className={className}
        onClick={onClick}
      >
        {i + 1}
      </button>
    );
  }

  return (
    <div className="bgc-white p-20 bd">
      <div className="layer w-100 mB-10">
        <h6 className="c-grey-900 float-left">
          {label}
        </h6>
        <form className="float-right">
          <div className="form-group d-inline mr-20">
            <label className="d-inline mr-10" htmlFor="exampleFormControlSelect1">
              Behavior
            </label>
            <select
              onChange={handleCategorySelected}
              value={category}
              className="form-control d-inline w-auto"
              id="exampleFormControlSelect1"
            >
              <option value="lifestyle">Lifestyle</option>
              <option value="purchasing">Purchasing</option>
            </select>
          </div>
          <div className="form-group d-inline">
            <label className="d-inline mr-10" htmlFor="exampleFormControlSelect1">
              Attitude
            </label>
            <select
              onChange={handleLikelinessSelected}
              value={likeliness}
              className="form-control d-inline w-auto"
              id="exampleFormControlSelect1"
            >
              <option value="Most likely to">More Likely</option>
              <option value="Less likely to">Less likely</option>
            </select>
          </div>
        </form>
      </div>
      <div className="mT-30">
        <div className="layers w-100">
          <ul style={{ minHeight: 240 }} className="list-group w-100 mt-16">
            {
              visibleBehaviours.map(
                behaviour => {
                  const { name } = behaviour

                  const rank = sortedBehaviours.findIndex(b => b === behaviour)

                  return (
                    <li key={name} className="list-group-item list-group-item-action">
                      <h5>{rank + 1}. {likeliness} {name}</h5>
                    </li>
                  )
                }
              )
            }
          </ul>
          <div className="btn-toolbar mt-16" role="toolbar" aria-label="Toolbar with button groups">
            <div className="btn-group mr-2" role="group" aria-label="First group">
              {buttons}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfluencerBehaviour = props => {
  if (!props.behaviour) return null

  const behaviours = props.behaviour
    .map(
      ({ consumption_preferences }) => consumption_preferences
    )
    .flat(1)
    .map(
      ({ data, name }) => data.map(
        behaviour => ({ ...behaviour, likeliness: name })
      )
    )
    .flat(1)

  const audiencePreferences = behaviours.filter(
    ({ name }) => audienceBehaviourCategoryByBehaviour[name] === 'preference'
  )

  const audienceBehaviours = behaviours.filter(
    ({ name }) => audienceBehaviourCategoryByBehaviour[name] === 'behaviour'
  )

  return (
    <>
      {
        audiencePreferences.length > 0 && (
          <>
            <div className="masonry-item  w-100">
              <h2>Preferences</h2>
            </div>

            <div className="masonry-item  w-100">
              <div className="row gap-20">
                <div className="col-12">
                  <Behaviour
                    label="AUDIENCE PREFERENCES"
                    behaviours={audiencePreferences}
                  />
                </div>
              </div>
            </div>
          </>
        )
      }

      {
        audienceBehaviours.length > 0 && (
          <>
            <div className="masonry-item  w-100">
              <h2>Behaviours</h2>
            </div>

            <div className="masonry-item  w-100">
              <div className="row gap-20">
                <div className="col-12">
                  <Behaviour
                    label="AUDIENCE BEHAVIOURS"
                    behaviours={audienceBehaviours}
                  />
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
};

export default enhance(InfluencerBehaviour);

import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { validateEmail } from '../../../../common/utils';
import { FORM_SIGNUP } from '../../../../common/constants/forms';
import { selectors } from '../../../../store/selectors';

const mapStateToProps = state => ({
  authInProgress: selectors.getAuthInProgress(state),
});

export default compose(
  connect(
    mapStateToProps
  ),
  reduxForm({
    form: FORM_SIGNUP,
    validate: values => {
      const errors = {};
      if (!values.name) {
        errors.name = 'Required';
      }
      if (!values.email) {
        errors.email = 'Required';
      } else if (!validateEmail(values.email)) {
        errors.email = 'Invalid email address';
      }
      if (!values.password) {
        errors.password = 'Required';
      } else if (values.password.length < 6) {
        errors.password = 'Password is too short';
      }
      if (!values.passwordConfirm) {
        errors.passwordConfirm = 'Required';
      } else if (values.password !== values.passwordConfirm) {
        errors.passwordConfirm = 'Do not match';
      }
      return errors;
    },
  })
);

import { createReducer, createActions } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const STORAGE_PREFIX = 'influencerSearchPage';

const INITIAL_STATE = {
  game_title_or_sector: 'League of Legends'
};



const { Types, Creators } = createActions({
  searchInfluencer: ['game_title_or_sector'],
}, {
  prefix: `${STORAGE_PREFIX  }/`,
});

export const searchInfluencer = (state, { game_title_or_sector }) => {
  console.log('searchInfluencer');
  console.log(state);
  return { ...state, game_title_or_sector: game_title_or_sector };
};

const HANDLERS = {
  [Types.SEARCH_INFLUENCER]: searchInfluencer,
};

const persistConfig = {
  key: STORAGE_PREFIX,
  storage,

  // will not save connections locally, permanent sync with firebase
  whitelist: [],
};

export const reducer = persistReducer(persistConfig, createReducer(INITIAL_STATE, HANDLERS));
export const actionTypes = Types;
export default Creators;

import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

import {
  getInfluencerTotalYoutubeSubscribers,
  getInfluencerTotalTwitchFollowers,
  getInfluencerNetworkFilter,
} from '../../selectors';

const mapStateToProps = state => ({
  networkFilter: getInfluencerNetworkFilter(state),
  totalTwitchFollowers: getInfluencerTotalTwitchFollowers(state),
  totalYoutubeSubscribers: getInfluencerTotalYoutubeSubscribers(state),
});

const enhance = connect(mapStateToProps);

const InfluencerAudienceReach = props => {
  const {
    networkFilter,
    totalTwitchFollowers = 0,
    totalYoutubeSubscribers = 0,
  } = props;

  return (
    <div className="masonry-item col-12">
      <div className="bd bgc-white">
        <div className="row">
          {
            networkFilter === 'twitch'
              ? (
                <div className="col col-xs-12">
                  <div className="pX-20 pT-20 w-100 mB-10">
                    <h6 className="lh-1">
                      TOTAL FOLLOWERS
                    </h6>
                  </div>
                  <div className="number-chart mT-50 mB-80">
                    <p className="number-chart-digit">{numeral(totalTwitchFollowers).format('0.0a')}</p>
                    <p className="number-chart-text">followers</p>
                  </div>
                </div>
              ) : (
                <div className="col col-xs-12">
                  <div className="pX-20 pT-20 w-100 mB-10">
                    <h6 className="lh-1">
                      TOTAL SUBSCRIBERS
                    </h6>
                  </div>
                  <div className="number-chart mT-50 mB-80">
                    <p className="number-chart-digit">{numeral(totalYoutubeSubscribers).format('0.0a')}</p>
                    <p className="number-chart-text">subscribers</p>
                  </div>
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default enhance(InfluencerAudienceReach);

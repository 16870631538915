import React from 'react';
import { connect } from 'react-redux';
import WordCloud from 'react-d3-cloud';
import numeral from 'numeral';

import { getInfluencerWordcloud, getInfluencerActivity, getInfluencerNetworkFilter } from '../../selectors';

const mapStateToProps = state => ({
  networkFilter: getInfluencerNetworkFilter(state),
  wordcloud: getInfluencerWordcloud(state),
  activity: getInfluencerActivity(state),
});

const enhance = connect(mapStateToProps);

const InfluencerActivity = props => {
  const {
    networkFilter,
    wordcloud = [],
    activity: {
      stream_time_days = 0,
      total_watch_time_days = 0,
    } = {},
  } = props;

  return (
    <div className="masonry-item w-100">
      {
        networkFilter === 'twitch'
          ? (
            <div className="layers bd bgc-white">
              <div className="row w-100">
                <div className="col-xs-12 col-md-4 border-right">
                  <div className="layers">
                    <div className="layer pX-20 pT-20 w-100 mB-10">
                      <h6 className="lh-1">TOTAL STREAMING TIME</h6>
                    </div>
                    <div className="layer w-100">
                      <div className="number-chart pT-60 pB-60">
                        <p className="number-chart-digit">{numeral(stream_time_days).format('0.0a')}</p>
                        <p className="number-chart-text">hours</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-4 border-right">
                  <div className="layers">
                    <div className="layer pX-20 pT-20 w-100 mB-10">
                      <h6 className="lh-1">HOURS WATCHED BY AUDIENCE</h6>
                    </div>
                    <div className="layer w-100">
                      <div className="number-chart pT-60 pB-60">
                        <p className="number-chart-digit">{numeral(total_watch_time_days).format('0.0a')}</p>
                        <p className="number-chart-text">hours</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <div className="layer pX-20 pT-20 w-100 mB-10">
                    <h6 className="lh-1">TALKING ABOUT</h6>
                  </div>
                  <div className="layer w-100 text-center">
                    <WordCloud
                      height={280}
                      width={280}
                      data={wordcloud}
                      fontSizeMapper={word => Math.log2(word.value) * 4}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row gap-20">
              <div className="col-4 col-md-4">
                <div className="layers bd bgc-white p-20">
                  <div className="layer w-100 mB-10">
                    <h6 className="lh-1">TALKING ABOUT</h6>
                  </div>
                  <div className="layer">
                    <WordCloud
                      height={280}
                      width={280}
                      data={wordcloud}
                      fontSizeMapper={word => 12 + (Math.log2(word.value) * 4)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
      }
    </div >
  );
};

export default enhance(InfluencerActivity);

export const PATH_ROOT = '/';
export const PATH_SIGNIN = '/signin';
export const PATH_SIGNUP = '/signup';
export const PATH_FORGOT = '/forgot';
export const PATH_CONNECTIONS = '/connections';
export const EMAIL_TEMPLATES = '/templates';
export const EMAIL_STATS = '/email-stats';
export const INFLUENCER_SEARCH = '/influencer-search';
export const INFLUENCERS = '/influencers';
export const INFLUENCER = '/influencer';

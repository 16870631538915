import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { selectors } from '../../../../store/selectors';
import { UserIsAuthenticated } from '../../../../common/auth';
import mapSelectorsToProps from '../../../../helpers/map-selectors-to-props';

const mapStateToProps = mapSelectorsToProps({ influencers: selectors.getInfluencers })

export default compose(
  UserIsAuthenticated,
  connect(mapStateToProps),
  withRouter
);

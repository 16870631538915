import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

import { getInfluencerTwitchAudienceEngagement, getInfluencerYoutubeAudienceEngagement, getInfluencerNetworkFilter } from '../../selectors';

const Engagement = props => {
  const {
    label,
    amount,
    amountLabel,
  } = props;

  return (
    <div className="col-md-4">
      <div className="layers bd bgc-white p-20">
        <div className="layer w-100 mB-10">
          <h6 className="lh-1">{label}</h6>
        </div>
        <div className="layer w-100">
          <div className="number-chart padding-tb-20">
            <p className="number-chart-digit">{numeral(amount).format('0.0a')}</p>
            <p className="number-chart-text">{amountLabel}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  networkFilter: getInfluencerNetworkFilter(state),
  youtubeAudienceEngagement: getInfluencerYoutubeAudienceEngagement(state),
  twitchAudienceEngagement: getInfluencerTwitchAudienceEngagement(state),
});

const enhance = connect(mapStateToProps);

/*
 * TODO: the mapping of these values to the engagement-cards is quite a mess
 * this needs some clarification of under which conditions to display what
 * and how to label it. seems completely randomized and not correctly matched
 * at the moment.
 */

const InfluencerAudienceEngagement = props => {
  const { networkFilter } = props;

  if (networkFilter === 'twitch') {
    const { twitchAudienceEngagement } = props;

    const {
      viewsDays = twitchAudienceEngagement && 0,
      averageViewersDays = twitchAudienceEngagement && 0,
      peakViewersDays = twitchAudienceEngagement && 0,
      followerVirality = twitchAudienceEngagement && 0,
      engagementAverage = twitchAudienceEngagement && 0,
      engagementPeak = twitchAudienceEngagement && 0,
    } = twitchAudienceEngagement || {};

    return (
      <div className="masonry-item w-100">
        <div className="row gap-20">
          {
            !isNaN(viewsDays) && (
              <Engagement
                label="VIEWS"
                amount={viewsDays}
                amountLabel="views"
              />
            )
          }

          {
            !isNaN(averageViewersDays) && (
              <Engagement
                label="AVERAGE CONCURRENT VIEWERS"
                amount={averageViewersDays}
                amountLabel="viewers/video"
              />
            )
          }

          {
            !isNaN(peakViewersDays) && (
              <Engagement
                label="MAX CONCURRENT VIEWERS"
                amount={peakViewersDays}
                amountLabel="viewers"
              />
            )
          }

          { /*
            * TODO: this is presumably incorrect
            */
            !isNaN(followerVirality) && (
              <Engagement
                label="INTERACTIONS"
                amount={followerVirality}
                amountLabel="interactions"
              />
            )
          }

          {
            !isNaN(engagementAverage) && (
              <Engagement
                label="ENGAGEMENT RATE"
                amount={engagementAverage}
                amountLabel="interaction/view"
              />
            )
          }

          {
            !isNaN(engagementAverage) && (
              <Engagement
                label="PEAK ENGAGEMENT RATE"
                amount={engagementPeak}
                amountLabel="interactions/view"
              />
            )
          }
        </div>
      </div>
    );
  }

  const { youtubeAudienceEngagement } = props;

  const {
    viewCount = youtubeAudienceEngagement && 0,
    averageViewCountPerVideo = youtubeAudienceEngagement && 0,
    averageCommentsPerVideo = youtubeAudienceEngagement && 0,
    likeDislikeRatio = youtubeAudienceEngagement && 0,
  } = youtubeAudienceEngagement || {};

  return (
    <div className="masonry-item w-100">
      <div className="row gap-20">
        {
          !isNaN(viewCount) && (
            <Engagement
              label="VIEWS"
              amount={viewCount}
              amountLabel="views"
            />
          )
        }

        {
          !isNaN(averageViewCountPerVideo) && (
            <Engagement
              label="AVERAGE VIEWS PER VIDEO VIEWERS"
              amount={averageViewCountPerVideo}
              amountLabel="views/video"
            />
          )
        }

        {
          !isNaN(averageCommentsPerVideo) && (
            <Engagement
              label="AVERAGE COMMENTS PER VIDEO"
              amount={averageCommentsPerVideo}
              amountLabel="comments/video"
            />
          )
        }

        {
          !isNaN(likeDislikeRatio) && (
            <Engagement
              label="LIKE DISLIKE RATIO"
              amount={likeDislikeRatio}
              amountLabel="like/dislike"
            />
          )
        }
      </div>
    </div>
  );
};

export default enhance(InfluencerAudienceEngagement);

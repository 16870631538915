import { connect } from 'react-redux';
import { withHandlers, compose } from 'recompose';

import { actions } from '../../../store/actions';
import { selectors } from '../../../store/selectors';

const mapStateToProps = state => ({
  userName: selectors.getUserName(state),
  userImage: selectors.getUserImage(state),
});

export default compose(
  connect(
    mapStateToProps
  ),
  withHandlers({
    onSignout: ({ dispatch }) => () => {
      dispatch(actions.signout());
    },
  })
);

import { createReducer, createActions } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as constants from './constants';

export const STORAGE_PREFIX = 'connectedAccounts';

const INITIAL_STATE = {
  connectedGmailAccounts: [],
  connectedFacebookAccounts: [
    { email: 'user1@facebook.com', id: 'someid1', tokens: {} },
    { email: 'user2@facebook.com', id: 'someid', tokens: {} },
  ],
};

const { Types, Creators } = createActions({
  bulkAddConnections: ['payload'],
  addConnection: ['connType', 'code'],
  removeConnection: ['connType', 'id'],
  requestGmailLink: null,
  requestFacebookLink: null,
  updateProfiles: null,
  profilesData: ['profiles'],
}, {
  prefix: `${STORAGE_PREFIX  }/`,
});

/* reducers */

export const bulkAddConnections = (state, { payload }) => {
  const res = { ...INITIAL_STATE };
  if (!payload) return res;
  res.connectedGmailAccounts = payload.gmail
    ? Object
      .keys(payload.gmail)
      .map(id => ({
        id,
        name: constants.LOADING_NAME,
        picture: '',
        email: payload.gmail[id].email,
        tokens: payload.gmail[id].tokens,
      }))
    : [];
  /*
  res.connectedFacebookAccounts = payload.facebook
    ? Object
      .keys(payload.facebook)
      .map(id => ({
        id,
        name: constants.LOADING_NAME,
        picture: ,
        email: payload.facebook[id].email,
        tokens: payload.facebook[id].tokens,
      }))
    : [];
    */
  console.warn('connections', res);
  return res;
};

export const addConnection = state => state;
export const removeConnection = state => state;
export const updateProfiles = state => state;

export const requestGmailLink = state => ({
  ...state, authGmail: '',
});

export const requestFacebookLink = state => ({
  ...state, authFacebook: '',
});

export const profilesData = (state, { profiles }) => {
  const res = { ...state };
  for (const profile of profiles) {
    const connection = (
      profile.connType === constants.TYPE_GOOGLE
        ? res.connectedGmailAccounts
        : res.connectedFacebookAccounts
    ).find(item => item.id === profile.id);
    if (connection) {
      connection.name = profile.name;
      connection.picture = profile.picture;
    }
  }
  return res;
};

export const HANDLERS = {
  [Types.ADD_CONNECTION]: addConnection,
  [Types.BULK_ADD_CONNECTIONS]: bulkAddConnections,
  [Types.REMOVE_CONNECTION]: removeConnection,
  [Types.REQUEST_GMAIL_LINK]: requestGmailLink,
  [Types.REQUEST_FACEBOOK_LINK]: requestFacebookLink,
  [Types.UPDATE_PROFILES]: updateProfiles,
  [Types.PROFILES_DATA]: profilesData,
};

const persistConfig = {
  key: STORAGE_PREFIX,
  storage,

  // will not save connections locally, permanent sync with firebase
  whitelist: [],
};

export const reducer = persistReducer(persistConfig, createReducer(INITIAL_STATE, HANDLERS));
export const actionTypes = Types;
export default Creators;

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';

import { history } from '../../auth';
import { store, persistor } from '../../../store';
import createRoutes from '../../routes';
import { ModalDialog } from '../../components';

class App extends React.Component {
  componentDidCatch (e) {
    window.alert('something went wrong')

    throw e
  }  

  render () {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            {createRoutes()}
          </Router>
          <ModalDialog />
        </PersistGate>
      </Provider>
    )
  }
}

export default App;

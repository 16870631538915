import React, { useState } from 'react'
import classNames from 'classnames'

import percent from '../utils/percent'
import through from '../utils/through'

const Traits = props => {
  const { traits, label } = props

  const [pagination, setPagination] = useState(0);

  const traitsBySignificance = traits.sort(
    (traitA, traitB) => traitB.percentile - traitA.percentile
  );

  const visibleTraits = traitsBySignificance.slice(
    pagination * 4,
    (pagination + 1) * 4
  );

  const buttons = through(0, traits.length / 4).map(
    i => {
      const onClick = () => { setPagination(i); };

      const className = classNames(
        'btn',
        'btn-light',
        {
          active: pagination === i,
        }
      );

      return (
        <button
          key={traitsBySignificance[i * 4].name}
          className={className}
          onClick={onClick}
        >
          {i + 1}
        </button>
      )
    }
  )

  return (
    <div className="bgc-white p-20 bd">
      <h6 className="c-grey-900">
        {label}
      </h6>
      <div className="mT-30">
        <div className="layers">
          <div className="w-100" style={{ minHeight: 200 }}>
            {
              visibleTraits.map(
                trait => {
                  const rank = traitsBySignificance.findIndex(t => t === trait);
            
                  const { name, percentile } = trait;
            
                  const significancePercentage = percent(1, percentile);
            
                  return (
                    <div
                      key={name}
                      className="layer w-100 position-relative"
                    >
                      <h5 className="mB-5">{rank + 1}. {name}</h5>
                      <span className="pull-right c-grey-600 fsz-sm position-absolute progress-percentage">
                        {significancePercentage}%
                      </span>
                      <div className="progress mT-10">
                        <div
                          className="progress-bar bgc-light-blue-500"
                          role="progressbar"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${significancePercentage}%` }}
                        >
                          <span className="sr-only">
                            {significancePercentage}% Complete
                            </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              )
            }
          </div>
          <div
            className="btn-toolbar mt-16"
            aria-label="Toolbar with button groups"
            role="toolbar"
          >
            <div
              className="btn-group mr-2"
              aria-label="First group"
              role="group"
            >
              {buttons}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Traits

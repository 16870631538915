import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import * as paths from '../../../../common/constants/paths';
import bgImage from '../../../../assets/images/bg.jpg';
import SignupForm from '../SignupForm';

const SignUpPage = ({
  signupWithPassword,
  signinGoogle,
  signinFacebook,
  onSubmitFail,
}) => {
  return (
    <div className="peers ai-s fxw-nw h-100vh">
      <div
        className="d-n@sm- peer peer-greed h-100 pos-r bgr-n bgpX-c bgpY-c bgsz-cv"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
      </div>
      <div className="col-12 col-md-4 peer pX-40 pY-80 h-100 bgc-white scrollable pos-r" style={{ minWidth: '320px' }}>
        <h4 className="fw-300 c-grey-900 mB-40">Register</h4>
        <SignupForm onSubmit={signupWithPassword} onSubmitFail={onSubmitFail} />
        <div className="peer">
          <Link to={paths.PATH_FORGOT}>Forgot your password?</Link>
        </div>
        <br />
        <button
          type="button"
          className="btn cur-p btn-primary"
          onClick={signinFacebook}
        >Register with Facebook</button><br />
        <button
          type="button"
          className="btn cur-p btn-danger"
          onClick={signinGoogle}
        >Register with Google</button><br />
        <Link to={paths.PATH_SIGNIN}>
          <button type="button" className="btn cur-p btn-secondary">Got account? Login!</button>
        </Link>
      </div>
    </div>
  );
};

SignUpPage.propTypes = {
  signupWithPassword: PropTypes.func.isRequired,
  signinGoogle: PropTypes.func.isRequired,
  signinFacebook: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func.isRequired,
};

export default SignUpPage;

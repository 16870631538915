import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { InputField } from '../../../../common/components';

export const SignupForm = ({
  pristine,
  authInProgress,
  handleSubmit,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field placeholder="required" name="name" type="text" component={InputField} label="Name"/>
      <Field placeholder="required" name="email" type="email" component={InputField} label="Email"/>
      <Field placeholder="required" name="password" type="password" component={InputField} label="Password"/>
      <Field placeholder="required" name="passwordConfirm" type="password" component={InputField} label="Confirm password"/>
      <div className="form-group">
        <button className="btn btn-primary" disabled={pristine || authInProgress}>
          {authInProgress ? 'Submitting' : 'Register'}
        </button>
      </div>
    </form>
  );
};

SignupForm.propTypes = {
  pristine: PropTypes.bool.isRequired,
  authInProgress: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

import { withHandlers, compose } from 'recompose';

import { actions } from '../../../../store/actions';
import { UserIsNotAuthenticated } from '../../../../common/auth';
import { Dialog } from '../../../../common/containers';

export default compose(
  UserIsNotAuthenticated,
  withHandlers({
    onSubmitFail: () => () => {
      Dialog.show({
        caption: 'Failed',
        message: 'Please fill all required fields',
        buttons: ['Got it'],
      });
    },
    signinGoogle: ({ dispatch }) => async () => {
      dispatch(actions.signinGoogle());
    },
    signinFacebook: ({ dispatch }) => async () => {
      dispatch(actions.signinFacebook());
    },
    signinWithPassword: ({ dispatch }) => async ({ email, password }) => {
      dispatch(actions.signinWithPassword(email, password));
    },
  })
);

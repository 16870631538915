import React from 'react'
import { Doughnut } from 'react-chartjs-2'

import percent from '../utils/percent'
import sum from '../utils/sum'

const AgeGroups = props => {
  const { data } = props

  const labels = Object.keys(data)

  const values = Object.values(data)

  return (
    <Doughnut
      data={{
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: [
              '#174EA8',
              '#1E5FCA',
              '#3677E2',
              '#6194E9',
              '#8CB0EF',
              '#C3D6F7',
              '#E3EAF7',
            ],
            hoverBackgroundColor: [
              '#174EA8',
              '#1E5FCA',
              '#3677E2',
              '#6194E9',
              '#8CB0EF',
              '#C3D6F7',
              '#E3EAF7',
            ],
          },
        ],
      }}
      options={{
        tooltips: {
          callbacks: {
            label: ({ index }, { labels, datasets }) => {
              const total = sum(datasets[0].data);

              return `${labels[index]}: ${percent(total, datasets[0].data[index])}%`;
            },
          },
        },
      }}
    />
  )
}

export default AgeGroups

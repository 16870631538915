import { createReducer, createActions } from 'reduxsauce';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const STORAGE_PREFIX = 'account';

export const INITIAL_STATE = {
  authUser: null,
  authInProgress: false,
};

const { Types, Creators } = createActions({
  userSignedIn: ['userData'],
  signupWithPassword: ['name', 'email', 'password'],
  signinWithPassword: ['email', 'password'],
  signinGoogle: null,
  signinFacebook: null,
  signout: null,
  resetPassword: ['email'],
  authInProgress: ['value'],
}, {
  prefix: `${STORAGE_PREFIX  }/`,
});

/* reducers */

export const userSignedIn = (state, { userData }) => {
  const user = {};
  const fieldsFilter = [
    'displayName',
    'email',
    'photoURL',
    'accessToken',
    'refreshToken',
  ];
  fieldsFilter.forEach(item => {
    userData[item] && (user[item] = userData[item]);
  });
  return {
    ...state,
    authUser: {
      ...state.authUser,
      ...user,
    },
  };
};

export const signinWithPassword = state => ({
  ...state, authInProgress: true,
});

export const signinGoogle = state => ({
  ...state, authInProgress: true,
});

export const signinFacebook = state => ({
  ...state, authInProgress: true,
});

export const signupWithPassword = state => ({
  ...state, authInProgress: true,
});

export const resetPassword = state => ({
  ...state, authInProgress: true,
});

export const authInProgress = (state, { value }) => ({
  ...state, authInProgress: !!value,
});

export const signout = state => state;

export const HANDLERS = {
  [Types.USER_SIGNED_IN]: userSignedIn,
  [Types.SIGNOUT]: signout,
  [Types.SIGNIN_WITH_PASSWORD]: signinWithPassword,
  [Types.SIGNIN_GOOGLE]: signinGoogle,
  [Types.SIGNIN_FACEBOOK]: signinFacebook,
  [Types.SIGNUP_WITH_PASSWORD]: signupWithPassword,
  [Types.RESET_PASSWORD]: resetPassword,
  [Types.AUTH_IN_PROGRESS]: authInProgress,
};

const persistConfig = {
  key: STORAGE_PREFIX,
  storage,
  blacklist: [],
};

export const reducer = persistReducer(persistConfig, createReducer(INITIAL_STATE, HANDLERS));
export const actionTypes = Types;
export default Creators;

import PropTypes from 'prop-types';
import { withHandlers, compose, setPropTypes } from 'recompose';

import { actions } from '../../../../store/actions';
import { UserIsNotAuthenticated } from '../../../../common/auth';
import { Dialog } from '../../../../common/containers';

export default compose(
  UserIsNotAuthenticated,
  setPropTypes({
    firebase: PropTypes.shape({
      login: PropTypes.func.isRequired,
    }),
  }),
  withHandlers({
    onSubmitFail: () => () => {
      Dialog.show({
        caption: 'Error',
        message: 'Please fill in all required fields',
        buttons: ['Got it'],
      });
    },
    signinGoogle: ({ dispatch }) => async () => {
      dispatch(actions.signinGoogle());
    },
    signinFacebook: ({ dispatch }) => async () => {
      dispatch(actions.signinFacebook());
    },
    signupWithPassword: ({ dispatch }) => async ({ name, email, password }) => {
      dispatch(actions.signupWithPassword(name, email, password));
    },
  })
);

import { connect } from 'react-redux'

import { getInfluencerTwitchComments, getInfluencerYoutubeComments } from '../../selectors';
import AudienceComments from '../../../../components/Comments'

const mapStateToProps = (state, { network }) => {
  if (network === 'twitch') {
    const twitchComments = getInfluencerTwitchComments(state)

    return {
      comments: twitchComments.map(
        comment => ({
          ...comment,
          source: 'twitch',
          text: comment.comment,
        })
      ),
    }
  }

  const youtubeComments = getInfluencerYoutubeComments(state)

  return {
    comments: youtubeComments.map(
      comment => ({
        ...comment,
        name: comment.author,
        time: comment.created_at,
        source: 'youtube',
      })
    ),
  }
}

export default connect(mapStateToProps)(AudienceComments)

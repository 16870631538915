import React from 'react';
import PropTypes from 'prop-types';

export const ModalDialog = ({ onCancel, onAccept, dialog: { caption, message, buttons } }) => {
  if (!caption) return '';
  document.activeElement && document.activeElement.blur();
  return (
    <div className="overlay">
      <div className="popup-overlay">
        <div className="popup">
          <div className="fw-500 fsz-lg">{caption}</div>
          <div className="fw-500 fsz-def">{message}</div>
          <div className="peers ai-c jc-sb fxw-nw">
            <div className="peer"></div>
            <div className="peer">
              {buttons.map((btnCaption, index) => {
                return <button
                  key={index}
                  className="btn btn-primary ml20"
                  onClick={() => {
                    index === buttons.length - 1 ? onCancel() : onAccept(index);
                  }}
                >
                  {btnCaption}
                </button>;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalDialog.propTypes = {
  dialog: PropTypes.shape({
    caption: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    buttons: PropTypes.array.isRequired,
  }),
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

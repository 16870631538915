import React from 'react';
import { connect } from 'react-redux';
import { Bar, BarChart, Cell, Tooltip, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import selectors from '../../../../store/selectors';

const mapStateToProps = state => ({ topGamingCommunitiesMentions: selectors.getTopGamingCommunitiesMentions(state) });

const enhance = connect(mapStateToProps);

const colors = [
  '#279ee0',
  '#b784b6',
  '#8bcd6d',
  '#f9a93b',
  '#ffb4a2',
  '#C2948A',
  '#80CED7',
  '#6184D8',
  '#43C59E',
  '#E2EB98',
];

const GamingCommunitiesMentionsChart = ({ topGamingCommunitiesMentions }) => {
  const displayedMentions = topGamingCommunitiesMentions.splice(0, 9);

  return (
    <div style={{ height: 240, width: '100%' }}>
      <ResponsiveContainer>
        <BarChart
          data={displayedMentions}
          layout="vertical"
          margin={{ left: 160 }}
        >
          <XAxis
            hide
            type="number"
          />
          <YAxis
            type="category"
            dataKey="subreddit"
            axisLine={false}
            tickLine={false}
          />
          <Tooltip />
          <Bar
            barSize={28}
            dataKey="mentions"
          >
            {
              displayedMentions.map(
                ({ subreddit }, i) => (
                  <Cell key={subreddit} fill={colors[i]} />
                )
              )
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default enhance(GamingCommunitiesMentionsChart);
